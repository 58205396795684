import React, { useEffect } from 'react';
import { useAppContext } from '../context/appContext';
import Loading from './Loading';
import Machine from '../components/Machine';
import Wrapper from '../assets/wrappers/JobsContainer';
import PageBtnContainer from './PageBtnContainer';

const MachinesContainer = () => {

    const { 
        getMachines, 
        machines, 
        isLoading, 
        page, 
        totalMachines,
        search,
        searchStatus,
        searchType,
        sort,
        activeInactive,
        machineStatus,
        region,
        numOfPages
     } = useAppContext();

    useEffect(() => {
        getMachines();
        // eslint-disable-next-line
    }, [page, search, searchStatus, searchType, sort, activeInactive, machineStatus, region])

    if (isLoading) {
        return <Loading center />
    }

    if (machines.length === 0) {
        return <Wrapper>
            <h2>No machines to display...</h2>
        </Wrapper>
    }

    return (
        <Wrapper>
            <h5>
                {totalMachines} machine{machines.length > 1 && 's'}
            </h5>
            <div className='jobs'>
                {machines.map((machine) => {
                    return <Machine key={machine._id} {...machine} />
                })}
            </div>
            {/* pagination buttons */}
            {numOfPages > 1 && <PageBtnContainer />}
        </Wrapper>
    )
}

export default MachinesContainer;