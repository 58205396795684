import React from 'react';
import { useAppContext } from '../../context/appContext';
import { Navigate } from "react-router-dom";

import { ProductsContainer, ProductSearchContainer } from '../../components';

const AllProducts = () => {

    const { 
        user
      } = useAppContext();

    if (user.role === 'stocker' || user.role === 'driver' || user.role === 'stocker-driver') {
        return <Navigate to="/"/>
    }

    return (
        <>
            <ProductSearchContainer />
            <ProductsContainer />
        </>
    )
};

export default AllProducts;