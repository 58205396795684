import styled from 'styled-components';

const Wrapper = styled.article`
  .percentage-bar {
    width: 100%;
    height: 30px;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }

  .percentage-bar-fill {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .percentage-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    color: white;
  }

  .white {
    color: white;
  }

  .black {
    color: black;
  }

  .green {
    background-color: green;
  }

  .orange {
    background-color: orange;
  }

  .red {
    background-color: red;
  }
`;

export default Wrapper;
