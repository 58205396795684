import React from 'react';
import { FormRow, FormRowSelect, Alert } from '.';
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/SearchContainer';

const ProductSearchContainer = () => {
  const {
    isLoading,
    showAlert,
    search,
    searchType,
    sort,
    sortOptions,
    category,
    categoryOptions,
    productTypeOptions,
    handleChange,
    clearFilters,
    isAdding,
    selectedMachineName,
    removeSelectedMachine,
    downloadProducts,
  } = useAppContext();

  const handleSearch = (e) => {
    if (isLoading) return;
    handleChange({ name: e.target.name, value: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    clearFilters();
  };

  const removeMachine = (e) => {
    e.preventDefault();
    removeSelectedMachine();
    //Add a function to app context to clear the addinMachineId and isAdding and selected machine
  };

  const downloadAllProducts = () => {
    downloadProducts();
  };

  return (
    <Wrapper>
      <form className='form'>
        {showAlert && <Alert />}
        <h4>
          {isAdding
            ? `adding product to ${selectedMachineName}`
            : 'search products'}
        </h4>
        <div className='form-center'>
          {/* search productName */}
          <FormRow
            type='text'
            name='search'
            value={search}
            handleChange={handleSearch}
          ></FormRow>
          {/* search by product type */}
          <FormRowSelect
            labelText='product type'
            name='searchType'
            value={searchType}
            handleChange={handleSearch}
            list={['all', ...productTypeOptions]}
          ></FormRowSelect>
          {/* category */}
          <FormRowSelect
            name='category'
            value={category}
            handleChange={handleSearch}
            list={['all', ...categoryOptions]}
          ></FormRowSelect>
          {/* sort */}
          <FormRowSelect
            name='sort'
            value={sort}
            handleChange={handleSearch}
            list={sortOptions}
          ></FormRowSelect>

          <button
            className='btn btn-block btn-danger'
            disabled={isLoading}
            onClick={handleSubmit}
          >
            clear filters
          </button>
          {isAdding && (
            <button
              className='btn btn-block btn-danger'
              disabled={isLoading}
              onClick={removeMachine}
            >
              remove machine
            </button>
          )}
          <button
            className='btn btn-block btn-danger'
            disabled={isLoading}
            onClick={downloadAllProducts}
          >
            Download
          </button>
        </div>
      </form>
    </Wrapper>
  );
};

export default ProductSearchContainer;
