import React, { useEffect } from 'react';
import { useAppContext } from '../context/appContext';
import Loading from './Loading';
import Note from './Note';
import Wrapper from '../assets/wrappers/JobsContainer';

const SelectedNotesContainer = () => {

    const { 
        getNotes, 
        notes, 
        isLoading, 
        totalNotes,
     } = useAppContext();

    useEffect(() => {
        getNotes();
        // eslint-disable-next-line
    }, [ ])

    if (isLoading) {
        return <Loading center />
    }

    if (notes.length === 0) {
        return <Wrapper>
            <h2>No notes to display...</h2>
        </Wrapper>
    }

    return (
        <Wrapper>
            <h5>
                {totalNotes} note{notes.length > 1 && 's'}
            </h5>
            <div className='jobs'>
                {notes.map((note) => {
                    return <Note key={note._id} {...note} />
                })}
            </div>
        </Wrapper>
    )
}

export default SelectedNotesContainer;