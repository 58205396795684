import React, { useEffect } from 'react';
import { FormRow, Alert } from '.';
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/DashboardFormPage';

const ProfileAdmin = () => {
    const { 
        user, 
        adminOverridePassword, 
        showAlert, 
        displayAlert, 
        isLoading, 
        createAdminOverrirde, 
        updateAdminOverride, 
        handleChange,
        getAllowedMachines,
        allowedMachines,
        getActiveMachines,
        activeMachines, 
    } = useAppContext();

    useEffect(() => {
        getAllowedMachines();
        getActiveMachines();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!adminOverridePassword) {
            displayAlert();
            return;
        }

        if (adminOverridePassword === '') {
            createAdminOverrirde(adminOverridePassword)
        } else {
            updateAdminOverride(adminOverridePassword)
        }
    }

    const handleAdminInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        handleChange({ name, value });
    }

    let admin = false
    if (user.role === 'admin' || user.role === 'super-admin') {
      admin = true
    }  

    return (
        <Wrapper>
            { admin ? 
            <>
            <form className='form' onSubmit={handleSubmit}>
                <h3>Admin</h3>
                {showAlert && <Alert />}
                <div className='form-center'>
                    <FormRow 
                        labelText='Password'
                        type='text' 
                        name='adminOverridePassword' 
                        value={adminOverridePassword} 
                        handleChange={handleAdminInput} 
                    />
                    <button className='btn btn-block' type='submit'disabled={isLoading}>
                        {isLoading ? 'Please Wait...' : 'Update'}
                    </button>
                </div>
            </form>
            <p>*Password must contain letters and a number and must be at least 8 characters</p>
            <hr></hr>
            <div>
                <h4>Subscription</h4>
                <p>Service: </p>
                <p>Machines Allowed: {allowedMachines} - Active Machines: {activeMachines}</p>
                <p>Contact Info:</p>
            </div>
            </>
            : <></> }
        </Wrapper>
    )
};

export default ProfileAdmin;