import React, { useState } from 'react'
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/FileUpload';

const FileUpload = () => {

    const {
        fileUpload
    } = useAppContext();

    const [file, setFile] = useState(null)

    const onInputChanged = (e) => {
        //console.log(e.target.files)
        setFile(e.target.files[0])
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        const data = new FormData()

        data.append('file', file)

        fileUpload(data)
        e.target.value = null

    }

    return (
        <Wrapper>
            <h4>Daily Upload</h4>
            <form method="post" action="#" id="#" onSubmit={onSubmit}>
                <div className="form-group files">
                    <label>Upload Your File </label>
                    <input 
                        type="file" 
                        onChange={onInputChanged}
                        className="form-control" 
                        multiple=""/>
                </div>
           
                <button>Upload</button>
            </form>
      </Wrapper>
    )
}

export default FileUpload