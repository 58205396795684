import styled from 'styled-components'

const Wrapper = styled.article`
  background: var(--white);
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;

  .container {
    display: flex;
    width: 95%;
  }

  .container div {
    flex: 1;
    padding: 5px;
  }
  
  .replace-form {
    margin-left: 10px;
    margin-right: 10px;
  }

  .replace-form button {
    margin-bottom: 5px;
  }

  .stocker-selected button {
    margin: 8px;
  }

  .products {
    text-align: center;
    height: 200px;
    overflow: auto; 
    border: 1px solid #ccc;
  }
`

export default Wrapper