import React , { useEffect } from 'react';
import { useAppContext } from '../../context/appContext';
import SelectedMachine from '../../components/SelectedMachine';
import Wrapper from '../../assets/wrappers/ProductsContainer';

const MachineDriver = () => {

    const { 
        selectedId,
        selectedMachineName,
        selectedMachineType,
        selectedStatus,
        selectedActivity,
        selectedRegion,
        selectedCountry,
        selectedUsState,
        selectedCity,
        selectedZip,
        selectedAddress,
        selectedNote,
        selectedTopSeller,
        selectedServicedOn,
        selectedServicedBy,
        selectedRows,
        selectedColumns,
        getProducts,
        products,
        totalProducts,
        page, 
        search,
        searchStatus,
        searchType,
        sort
     } = useAppContext();

     useEffect(() => {
        getProducts();
        // eslint-disable-next-line
    }, [page, search, searchStatus, searchType, sort])

  return (
    <Wrapper>
    <SelectedMachine machine={{ 
        selectedId,
        selectedMachineName,
        selectedMachineType,
        selectedStatus,
        selectedActivity,
        selectedRegion,
        selectedCountry,
        selectedUsState,
        selectedCity,
        selectedZip,
        selectedAddress,
        selectedNote,
        selectedTopSeller,
        selectedServicedOn,
        selectedServicedBy,
        selectedRows,
        selectedColumns
    }}/>
</Wrapper>
  )
}

export default MachineDriver