import styled from 'styled-components'

const Wrapper = styled.article`
  background: var(--white);
  border-radius: var(--borderRadius);
  display: grid;
  grid-template-rows: 1fr auto;
  box-shadow: var(--shadow-2);

  h4 {
    text-align: center;
    margin-left: 0.5rem;
  }

  p {
    margin-left: 0.5rem;
  }

  .errorTable {
    
    text-align: center;
  }

  table {
    margin: 0 auto;
    width: 80%;
  }

  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .view-btn,
  .hide-btn {
    letter-spacing: var(--letterSpacing);
    cursor: pointer;
    height: 30px;
    width: 200px;
    margin-left: 0.5rem;
  }

  .view-btn {
    color: var(--blue-dark);
    background: var(--blue-light);
  }

  .hide-btn {
    color: var(--red-dark);
    background: var(--red-light);
  }
`

export default Wrapper