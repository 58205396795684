import React from 'react';
import { Link } from 'react-router-dom';
import { FaDropbox, FaLocationArrow } from 'react-icons/fa';
import DashboardInfo from './DashboardInfo';
import { useAppContext } from '../context/appContext';

import Wrapper from '../assets/wrappers/Job';

const StockerDashboard = ({ needsStock }) => {
  const { runStockCheck } = useAppContext();

  return (
    <Wrapper>
      <header>
        <div className='main-icon'>
          <FaDropbox />
        </div>
        <div className='info'>
          <h5>Picker Dashbaord</h5>
          <p></p>
        </div>
      </header>
      <div className='content'>
        <div className='action-center'>
          <h4>ACTION CENTER</h4>
          <button
            type='button'
            className='btn view-btn'
            onClick={() => runStockCheck()}
          >
            Run Stock Check
          </button>
        </div>
        <div className='content-center'>
          {<DashboardInfo icon={<FaLocationArrow />} text={needsStock} />}
        </div>
        <footer>
          <div className='action'>
            <Link to='/stocker' className='btn edit-btn'>
              Go
            </Link>
          </div>
        </footer>
      </div>
    </Wrapper>
  );
};

export default StockerDashboard;
