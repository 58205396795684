import React from 'react';
import { useAppContext } from '../../context/appContext';
import { Navigate } from "react-router-dom";

import { UsersContainer, UserSearchContainer } from '../../components';

const AllUsers = () => {

    const { 
        user
      } = useAppContext();

      if (user.role === 'stocker' || user.role === 'driver' || user.role === 'stocker-driver') {
        return <Navigate to="/"/>
    }

    return (
        <>
            <UserSearchContainer />
            <UsersContainer />
        </>
    )
};

export default AllUsers;