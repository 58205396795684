import React from 'react';
import Wrapper from '../assets/wrappers/MachineProductDriverSmall';
import { useAppContext } from '../context/appContext';
import moment from 'moment';

const MachineProductDriverSmall = ({
  _id,
  productName,
  productType,
  location,
  price,
  sales,
  maxStock,
  currentStock,
  stocked,
  stockedDate,
  stockerReplaced,
  stockerReplacedProductName,
  stockerReplacedProductType,
  stockerReplacedProductPrice,
  stockerReplacedProductMaxStock,
  replaceFlag,
  independentPricing,
}) => {
  const { updateProductInfoView } = useAppContext();

  let date = moment(stockedDate);
  date = date.format('MMM Do, YYYY, h:mm:ss a ');

  const neededStock = maxStock - currentStock;

  const handleClick = () => {
    // Handle the click event
    updateProductInfoView(
      _id,
      productName,
      productType,
      location,
      price,
      sales,
      maxStock,
      currentStock,
      stocked,
      stockedDate,
      stockerReplaced,
      stockerReplacedProductName,
      stockerReplacedProductType,
      stockerReplacedProductPrice,
      stockerReplacedProductMaxStock,
      replaceFlag,
      independentPricing,
    );
  };

  return (
    <Wrapper>
      <div onClick={handleClick} style={{ cursor: 'pointer' }}>
        <header>
          {stockerReplaced ? (
            <div className={'main-icon filled'}>{location}</div>
          ) : (
            <div
              className={
                maxStock > 0 && stocked > 0
                  ? 'main-icon needs-fill'
                  : 'main-icon'
              }
            >
              {location}
            </div>
          )}
          <div className='info'>{productName}</div>
        </header>
      </div>
    </Wrapper>
  );
};

export default MachineProductDriverSmall;
