import React, { useState } from 'react';
import { useAppContext } from '../context/appContext';
import { FormRow, FormRowSelect, Alert, StockerProductsContainer } from '.';
import Loading from './Loading';
import Wrapper from '../assets/wrappers/StockerReplaceProduct';

const StockerReplaceProduct = () => {
  const [productName, setProductName] = useState('');
  const [productType, setProductType] = useState('all');
  const [productSort, setProductSort] = useState('a-z');
  const [categoryType, setCategoryType] = useState('all');

  const {
    selectedProductId,
    selectedProductName,
    selectedProductType,
    selectedLocation,
    selectedProductPrice,
    selectedProductSales,
    selectedProductMaxStock,
    isLoading,
    selectedId,
    selectedMachineName,
    stockerReplaceProduct,
    productTypeOptions,
    category,
    categoryOptions,
    stockerSortOptions,
    showAlert,
    stockerReplacedProductName,
    stockerReplacedProductPrice,
    stockerProductSearch,
  } = useAppContext();

  if (isLoading) {
    return <Loading center />;
  }

  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleProductTypeChange = (event) => {
    setProductType(event.target.value);
  };

  const handleCategoryTypeChange = (event) => {
    setCategoryType(event.target.value);
  };

  const handleProductSortChange = (event) => {
    setProductSort(event.target.value);
  };

  const handleClear = () => {
    setProductName('');
    setProductType('all');
    setProductSort('a-z');
    setCategoryType('all');

    stockerProductSearch(productName, productType, productSort, categoryType);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    stockerProductSearch(productName, productType, productSort, categoryType);
  };

  return (
    <Wrapper>
      {selectedProductId && (
        <div className='container'>
          <hr></hr>
          <div className='form-div'>
            <form className='replace-form'>
              {showAlert && <Alert />}
              <p>Search Products</p>
              {/* search productName */}
              <FormRow
                type='text'
                name='search'
                value={productName}
                handleChange={handleProductNameChange}
              ></FormRow>
              {/* search by product type */}
              <FormRowSelect
                labelText='product type'
                name='searchType'
                value={productType}
                handleChange={handleProductTypeChange}
                list={['all', ...productTypeOptions]}
              ></FormRowSelect>
              {/* category */}
              <FormRowSelect
                name='category'
                value={categoryType}
                handleChange={handleCategoryTypeChange}
                list={['all', ...categoryOptions]}
              ></FormRowSelect>
              {/* sort */}
              <FormRowSelect
                name='sort'
                value={productSort}
                handleChange={handleProductSortChange}
                list={stockerSortOptions}
              ></FormRowSelect>
              <button
                className='btn btn-block btn-danger'
                disabled={isLoading}
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                className='btn btn-block btn-danger'
                type='button'
                onClick={handleClear}
              >
                Clear
              </button>
            </form>
          </div>
          <div className='products-div'>
            <p>Replace Product</p>
            <div className='products'>
              <StockerProductsContainer />
            </div>
            {stockerReplacedProductName && (
              <div className='stocker-selected'>
                <div>Selected Product: {stockerReplacedProductName}</div>
                <div>
                  Selected Price: $
                  {Number(stockerReplacedProductPrice).toFixed(2)}
                </div>
                <button
                  className='btn btn-block btn-danger'
                  disabled={isLoading}
                  onClick={() =>
                    stockerReplaceProduct(
                      selectedId,
                      selectedMachineName,
                      selectedProductId,
                      selectedProductName,
                      selectedProductType,
                      selectedProductSales,
                      selectedLocation,
                      selectedProductPrice,
                      selectedProductMaxStock,
                    )
                  }
                >
                  Replace
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default StockerReplaceProduct;
