import styled from 'styled-components'

const Wrapper = styled.section`
margin-top: 0;
h2 {
  text-transform: none;
}
& > h5 {
  font-weight: 700;
}
.items {
  margin-botttom: 5px;
  text-align: center;
  display: block;
  width: 500px;
} 
`
export default Wrapper
