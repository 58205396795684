import styled from 'styled-components'

const Wrapper = styled.article`
  background: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  border: 1px solid #ccc;
  margin-bottom: 7px;

  .content {
    text-align: center;
    margin-bottom: 5px;
    h5 {
      margin-bottom: 0.25rem;
    }
    p {
      margin: 0;
      text-transform: capitalize;
      color: var(--grey-400);
      letter-spacing: var(--letterSpacing);
    }
  }
  
  .select-btn{
    letter-spacing: var(--letterSpacing);
    cursor: pointer;
    height: 30px;
    width: 90%;
  }

  .select-btn {
    color: var(--blue-dark);
    background: var(--blue-light);
    margin-right: 0.5rem;
  }

  &:hover .actions {
    visibility: visible;
  }
`

export default Wrapper
