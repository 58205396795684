import React, { useEffect } from 'react';
import { useAppContext } from '../../context/appContext';
import { MachinesContainer, MachinesSearchContainer } from '../../components';

const Stocker = () => {
  const { setMachineStatusStock, clearSelectedProduct } = useAppContext();

  useEffect(() => {
    setMachineStatusStock();
    clearSelectedProduct();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <h1>Picker</h1>
      <MachinesSearchContainer />
      <MachinesContainer />
    </>
  );
};

export default Stocker;
