import React from 'react'
import { useAppContext } from '../context/appContext';

import Wrapper from '../assets/wrappers/Message';

const ViewMessageComponent = ({
    messages,
    totalMessages,
}) => {

  const { 
    markMessageAsRead,
    user
  } = useAppContext();

    const handleMarkedAsRead = (id, receiverId) => {
        
        console.log(`Message: ${id}`)
        markMessageAsRead(id, receiverId)

      }

  return (
    <Wrapper>
        {messages.map((message) => {

            if (message.markedRead) {
                return(
                    <></>
                )
            }

            return(
                <div className="App">
                    <table className='messages'>
                        <thead>
                            <tr>
                                <th>Sender</th>
                                <th>Type</th>
                                <th>Message</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {messages.map((message, key) => {
                            return (
                                <tr key={key}>
                                    <td>{message.senderName}</td>
                                    <td>{message.messageType}</td>
                                    <td>{message.message}</td>
                                    <td>
                                        <button type='button' className={'btn btn-block sumbit-btn'} onClick={() => handleMarkedAsRead(message._id, user._id)} >
                                        {'Mark as Read'}
                                        </button>
                                    </td>
                                </tr>
                            )
                     })}
                     </tbody>
                </table>
            </div>
            )
        })}
    </Wrapper>
  )
}

export default ViewMessageComponent