import React from 'react'
import { Link } from 'react-router-dom';

import { FaLocationArrow } from 'react-icons/fa';

import { AiFillCar } from 'react-icons/ai';

import DashboardInfo from './DashboardInfo';

import Wrapper from '../assets/wrappers/Job'

const DriverDashboard = ({
  needsDelivered
}) => {

  return (
    <Wrapper>
       <header>
                <div className='main-icon'>
                  <AiFillCar />
                </div>
                <div className='info'>
                    <h5>Driver Dashbaord</h5>
                    <p></p>
                </div>
            </header>
            <div className='content'>
                <div className='content-center'>
                    { 
                        <DashboardInfo icon={<FaLocationArrow/>} text={needsDelivered} />
                    }
                </div>
                <footer>
                    <div className='action'>
                        {
                          <Link to='/driver' className='btn edit-btn'>
                            Go 
                          </Link>
                        }
                    </div>
                </footer>
            </div>
    </Wrapper>
  )
}

export default DriverDashboard