import React from 'react';
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/Job';
import moment from 'moment';

const  Action = ({ 
    _id, 
    action,
    createdAt
}) => {

     const {  } = useAppContext(); 
    let date = moment(createdAt);
    date = date.format('MMM Do, YYYY ')
    return (
        <Wrapper>
            <header>
                <div className='info'>
                    <h5>Date: {date}</h5>
                </div>
            </header>
            <div className='content'>
                <div className='content-center'>
                    {/* <div>{newNote}</div> */}
                    <p>{action}</p>
                </div>
                <footer>
                    <div className='action'>
                        {/* <button type='button' className='btn delete-btn' onClick={() => deleteNote(_id)} >
                            Delete
                        </button> */}
                    </div>
                </footer>
            </div>
        </Wrapper>
    )
}

export default Action;