import styled from 'styled-components'

const Wrapper = styled.section`

  .notification-button {
    position: relative;
    display: inline-block;
  }
 

  .notification-counter {
    position: absolute;
    top: -8px;;
    right: -8px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0.2em 0.5em;
    font-size: 12px;
  }
`
export default Wrapper

