import React, { useState, useEffect } from "react";
import { Logo, FormRow, Alert } from '../components';
import Wrapper from '../assets/wrappers/RegisterPage';
import { useAppContext } from '../context/appContext';
import { useNavigate } from 'react-router-dom';

const initialState = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    registrationCode: '',
    isMember: true,
}

const Register = () => {
    const navigate = useNavigate();
    const [values, setValues] = useState(initialState); 
    const { 
        user, 
        isLoading, 
        showAlert, 
        displayAlert, 
        setupUser 
    } = useAppContext();

    const toggleMember = () => {
        setValues({...values, isMember:!values.isMember})
    }

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const {name, email, password, confirmPassword, isMember, registrationCode} = values;
        if (!email || !password || (!isMember && !confirmPassword) || (!isMember && !name) || (!isMember && !registrationCode)) {
            displayAlert();
            return;
        }
        const currentUser = {name, email, password, confirmPassword, registrationCode}
        if (isMember) {
            setupUser({ 
                currentUser, 
                endpoint: 'login', 
                alertText: 'Login Successful! Redirecting...'});
        } else {
            setupUser({ 
                currentUser, 
                endpoint: 'register', 
                alertText: 'Registration Successful! Redirecting...'});
        }
    }

    useEffect(() => {
        if (user) {
            setTimeout(() => {
                navigate('/');
            }, 3000)
        }
    }, [user, navigate]);
 
    return <Wrapper className='full-page'>
        <form className='form' onSubmit={onSubmit}>
            <Logo />
            <h3>{values.isMember ? 'Login' : 'Register'}</h3>
            {showAlert && <Alert />}
            {/* name input */}
            {!values.isMember && (
            <FormRow 
                type="text" 
                name="name" 
                value={values.name} 
                handleChange={handleChange} 
            />
            )}
            {/* email input */}
            <FormRow 
                type="email" 
                name="email" 
                value={values.email} 
                handleChange={handleChange} 
            />

            {/* password input */}
            <FormRow 
                type="password" 
                name="password" 
                value={values.password} 
                handleChange={handleChange} 
            />
            {/* confirm password input */}
            {!values.isMember && (
            <FormRow
                type="password"
                labelText="confirm password"
                name="confirmPassword"
                value={values.confirmPassword}
                handleChange={handleChange}
            />
            )}
            {/* registration code input */}
            {!values.isMember && (
            <FormRow 
                type="text" 
                labelText="registration code"
                name="registrationCode" 
                value={values.registrationCode} 
                handleChange={handleChange} 
            />
            )}
            
            <button type='submit' className='btn btn-block' disabled={isLoading}>Submit</button>
            <p>
                {values.isMember ? 'Not a member yet?' : 'Already a member?'}
                <button type='button' onClick={toggleMember} className='member-btn'>
                    {values.isMember ? 'Register' : 'Login'}
                </button>
            </p>
        </form>
    </Wrapper>
};

export default Register;
