import React, { useEffect } from 'react'

import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/ErrorReport'

const ErrorReport = () => {

    const {
        getErrorReport,
        emptyColumnSpin,
        machineNotFound,
        errors,
        showErrorReport,
        showTransactionReport,
        toggleErrorReport,
        getTransactionReport,
        transactionCount,
        transactions,
        toggleTransactionReport
    } = useAppContext()

    useEffect(() => {
        getErrorReport()
        getTransactionReport()
        // eslint-disable-next-line
    }, [])

  return (
    <Wrapper>
        <h4>Error Report</h4>
        <p>Empty Column Spins: {emptyColumnSpin} Machine Not Found: {machineNotFound}</p>
        {errors && errors.length > 0 &&
            <>
            <button type='button' className={showErrorReport ? 'btn hide-btn' : 'btn view-btn'}  onClick={() => toggleErrorReport()} >
                {showErrorReport ? 'Hide Error Report' : 'View Error Report'}
            </button>
            <br></br>
            <button type='button' className={showTransactionReport ? 'btn hide-btn' : 'btn view-btn'}  onClick={() => toggleTransactionReport()} >
                {showErrorReport ? 'Hide Transactions' : 'View Transactions'}
            </button>
            {showTransactionReport &&
                <div className="errorTable">
                    <br></br>
                    <table>
                        <thead>
                            <tr>
                                <th>Machine Name</th>
                                <th>Product Name</th>
                                <th>Location</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                        {transactions.map((val, key) => {
                            return (
                                <tr key={key}>
                                    <td>{val.machineName}</td>
                                    <td>{val.productName}</td>
                                    <td>{val.location}</td>
                                    <td>{val.price}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>}
                <br></br>
                {showErrorReport &&
                <div className="errorTable">
                    <br></br>
                    <table>
                        <thead>
                            <tr>
                                <th>Error Type</th>
                                <th>Message</th>
                            </tr>
                        </thead>
                        <tbody>
                        {errors.map((val, key) => {
                            return (
                                <tr key={key}>
                                    <td>{val.type}</td>
                                    <td>{val.msg}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                }
            </>
        }

        

        {/* {showErrorReport && } */}
    </Wrapper>
  )
}

export default ErrorReport