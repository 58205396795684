import React from 'react'
import { useAppContext } from '../context/appContext';

const AlertItem = ({ alert }) => {

    const {
        message,
        alertType,
        severity,
        _id
    } = alert;

    const {
        archiveAlert
    } = useAppContext();

    const archive = () => {
        archiveAlert(_id);
    }

  return (
    <div>
        {message}
        <button type='button' className='btn view-btn' onClick={archive}>Archive</button>
    </div>
  )
}

export default AlertItem