import React from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../context/appContext';
import { FaLocationArrow, FaBriefcase, FaCalendarAlt } from 'react-icons/fa';
import Wrapper from '../assets/wrappers/Job';
import moment from 'moment';
import ProductInfo from './ProductInfo';

const Product = ({
  _id,
  productName,
  productType,
  vending,
  caseCost,
  caseQty,
  supplier,
  category,
  createdAt,
}) => {
  const { setEditProduct, deleteProduct, isAdding, addMachineProduct } =
    useAppContext();
  let date = moment(createdAt);
  date = date.format('MMM Do, YYYY ');
  return (
    <Wrapper>
      <header>
        <div className='main-icon'>{productName.charAt(0)}</div>
        <div className='info'>
          <h5>{productName}</h5>
          <p>{productType}</p>
        </div>
      </header>
      <div className='content'>
        <div className='content-center'>
          <div>Supplier: {supplier}</div>
          <div>Price: ${Number(vending).toFixed(2)}</div>
          <div>Case Cost: {caseCost}</div>
          <div>Case QTY: {caseQty}</div>
          <div>Category: {category}</div>
        </div>
        <footer>
          <div className='action'>
            {isAdding ? (
              <>
                <button
                  type='button'
                  className='btn delete-btn'
                  onClick={() => addMachineProduct(_id)}
                >
                  Add
                </button>
              </>
            ) : (
              <>
                <Link
                  to='/add-product'
                  className='btn edit-btn'
                  onClick={() => setEditProduct(_id)}
                >
                  Edit
                </Link>
                <button
                  type='button'
                  className='btn delete-btn'
                  onClick={() => deleteProduct(_id)}
                >
                  Delete
                </button>
              </>
            )}
          </div>
        </footer>
      </div>
    </Wrapper>
  );
};

export default Product;
