import React, { useEffect } from 'react';
import { useAppContext } from '../../context/appContext';
import { MachinesContainer, MachinesSearchContainer } from '../../components';

const Driver = () => {

  const { 
    setMachineStatusDriver,
    clearSelectedProduct
  } = useAppContext();

  useEffect(() => {
    setMachineStatusDriver()
    clearSelectedProduct()
    // eslint-disable-next-line
}, [])  
      

    return (
      <>
        <h1>Driver</h1>
        <MachinesSearchContainer />
        <MachinesContainer />
      </>
    )
};

export default Driver;