import React, { useEffect } from 'react';
import { useAppContext } from '../context/appContext';
import Loading from './Loading';
import Action from './Action';
import Wrapper from '../assets/wrappers/JobsContainer';

const UserActionsContainer = () => {

    const {
        selectedUserId,
        selectedUser, 
        getActions, 
        actions, 
        totalActions,
        isLoading
     } = useAppContext();

     useEffect(() => {
        getActions(selectedUserId);
        // eslint-disable-next-line
    }, [selectedUserId])

    if (isLoading) {
        return <Loading center />
    }

    if (actions.length === 0) {
        return <Wrapper>
            <h2>No Actions to display...</h2>
        </Wrapper>
    }

    return (
        <Wrapper>
            <h5>
                {totalActions} Action{actions.length > 1 && 's'}
            </h5>
            <div className='jobs'>
                {actions.map((action) => {
                    return <Action key={action._id} {...action} />
                })}
            </div>
        </Wrapper>
    )
}

export default UserActionsContainer;