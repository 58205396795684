import React, { useEffect } from 'react';
import { useAppContext } from '../context/appContext';
import Loading from './Loading';
import User from '../components/User';
import Wrapper from '../assets/wrappers/JobsContainer';
import PageBtnContainer from './PageBtnContainer';

const UsersContainer = () => {

    const { 
        getUsers, 
        users, 
        isLoading, 
        page, 
        totalUsers,
        search,
        searchStatus,
        searchType,
        sort,
        numOfPages
     } = useAppContext();

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line
    }, [ page, search, searchStatus, searchType, sort])

    if (isLoading) {
        return <Loading center />
    }

    if (users.length === 0) {
        return <Wrapper>
            <h2>No users to display...</h2>
        </Wrapper>
    }

    return (
        <Wrapper>
            <h5>
                {totalUsers} User{users.length > 1 && 's'}
            </h5>
            <div className='jobs'>
                {users.map((user) => {
                    return <User key={user._id} {...user} />
                })}
            </div>
            {/* pagination buttons */}
            {numOfPages > 1 && <PageBtnContainer />}
        </Wrapper>
    )
}

export default UsersContainer;