import React, { useEffect } from 'react';
import { FormRow, FormRowSelect, Alert } from '../../components';
import { useAppContext } from '../../context/appContext';
import { Navigate } from "react-router-dom";

import Wrapper from '../../assets/wrappers/DashboardFormPage';

const AddMachine = () => {

    const { 
        isLoading,
        isEditing,
        showAlert, 
        displayAlert, 
        handleChange,
        clearValues,
        machineName,
        deviceId,
        machineType,
        machineTypeOptions, 
        region,
        regionOptions,
        country,
        usState,
        city,
        zip,
        address,
        note,
        createMachine,
        editMachine,
        user,
        rows,
        columns,
        getAllowedMachines,
        allowedMachines,
        getActiveMachines,
        activeMachines, 
        displayCustomAlert
      } = useAppContext();

      useEffect(() => {
        getAllowedMachines();
        getActiveMachines();
    }, []);

      const handleSubmit = (e) => {
          e.preventDefault();

          if (!machineName || !machineType || !region || !country || !usState || !city || !zip || !address || !note || !rows || !columns) {
              displayAlert();
              return;
          }

          if (isEditing) {
            editMachine();
            return;
          }

          if (activeMachines == allowedMachines) {
            //TODO: Fix alert
            //console.log(`${activeMachines}/${allowedMachines}`);
            displayCustomAlert('No more Machines available. Contact support for assistance.', 5000);
            return;
          }

          createMachine();
      }

      const handleMachineInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        handleChange({ name, value });
      }

      if (user.role === 'stocker' || user.role === 'driver' || user.role === 'stocker-driver') {
        return <Navigate to="/"/>
    }  

    return (
        <Wrapper>
            <form className='form'>
                <h3>{isEditing ? 'edit machine' : 'add machine'}</h3>
                {showAlert && <Alert />}
                <div className='form-center'>
                    {/* machineName */}
                    <FormRow 
                        labelText='Machine Name'
                        type='text' 
                        name='machineName' 
                        value={machineName}
                        handleChange={handleMachineInput}
                    />
                    {/* deviceId */}
                    <FormRow 
                        labelText='Device ID'
                        type='text' 
                        name='deviceId' 
                        value={deviceId}
                        handleChange={handleMachineInput}
                    />
                    {/* machineType */}
                    <FormRowSelect 
                        name='machineType' 
                        value={machineType}
                        labelText='Machine Type'
                        handleChange={handleMachineInput}
                        list={machineTypeOptions}
                    />
                    {/* region */}
                    <FormRowSelect 
                        type='text' 
                        name='region' 
                        value={region}
                        handleChange={handleMachineInput}
                        list={regionOptions}
                    />
                    {/* country */}
                        <FormRow
                        type='text'
                        name='country'
                        value={country}
                        handleChange={handleMachineInput}
                    />
                    {/* state */}
                    <FormRow 
                        labelText='State'
                        type='text'
                        name='usState'
                        value={usState}
                        handleChange={handleMachineInput}
                    />
                    {/* city */}
                    <FormRow 
                        type='text'
                        name='city'
                        value={city}
                        handleChange={handleMachineInput}
                    />
                    {/* zip */}
                    <FormRow 
                        type='text'
                        name='zip'
                        value={zip}
                        handleChange={handleMachineInput}
                    />
                    {/* address */}
                    <FormRow 
                        type='text'
                        name='address'
                        value={address}
                        handleChange={handleMachineInput}
                    />
                    {/* note */}
                    <FormRow 
                        type='text'
                        name='note'
                        value={note}
                        handleChange={handleMachineInput}
                    />
                    {/* rows */}
                    <FormRow 
                        type='number'
                        name='rows'
                        value={rows}
                        handleChange={handleMachineInput}
                    />
                    {/* columns */}
                    <FormRow 
                        type='number'
                        name='columns'
                        value={columns}
                        handleChange={handleMachineInput}
                    />
                    {/* btn Container */}
                    <div className='btn-container'>
                        <button 
                            type='submit' 
                            className='btn btn-block sumbit-btn' 
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            submit
                        </button>
                        <button 
                            className='btn btn-block clear-btn' 
                            onClick={(e) => {
                                e.preventDefault();
                                clearValues();
                            }}
                            >
                                clear
                            </button>
                    </div>

                </div>
            </form>
        </Wrapper>
    )
};

export default AddMachine;