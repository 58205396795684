import React from 'react';
import { useAppContext } from '../context/appContext';
import Loading from './Loading';
import Wrapper from '../assets/wrappers/DriverReplaceProduct';

const DriverReplaceProduct = () => {
  const {
    isLoading,
    selectedLocation,
    selectedProductStockerReplaced,
    selectedProductId,
    selectedId,
    selectedMachineName,
    driverReplaceProduct,
    stockerReplacedProductName,
    stockerReplacedProductType,
    stockerReplacedProductPrice,
    stockerReplacedProductMaxStock,
    replaceItemFlag,
    toggleDriverReplace,
  } = useAppContext();

  if (isLoading) {
    return <Loading center />;
  }

  return (
    <Wrapper>
      {selectedProductStockerReplaced && (
        <div className='replaced-product'>
          <h5>Replace Stocked Product</h5>
          <div className='container'>
            <div>Product Name: {stockerReplacedProductName}</div>
            <div>Product Type: {stockerReplacedProductType}</div>
            <div>
              Product Price : ${Number(stockerReplacedProductPrice).toFixed(2)}
            </div>
            <div>New Max Stock: {stockerReplacedProductMaxStock}</div>
          </div>
          <button
            type='button'
            className='btn btn-block btn-danger'
            onClick={() => replaceItemFlag(selectedId, selectedProductId)}
          >
            Replace Issue
          </button>
          <button
            className='btn btn-block btn-danger'
            disabled={isLoading}
            onClick={() =>
              driverReplaceProduct(
                selectedId,
                selectedMachineName,
                selectedLocation,
                selectedProductId,
                stockerReplacedProductName,
                stockerReplacedProductType,
                stockerReplacedProductPrice,
                stockerReplacedProductMaxStock,
              )
            }
          >
            Replace
          </button>
          <button
            className='btn btn-block btn-edit'
            disabled={isLoading}
            onClick={() => toggleDriverReplace()}
          >
            Close
          </button>
        </div>
      )}
    </Wrapper>
  );
};

export default DriverReplaceProduct;
