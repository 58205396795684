import React from 'react';
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/Job';
import moment from 'moment';

const  Note = ({ 
    _id, 
    newNote,
    severity,
    noteType,
    createdAt,
    
}) => {

     const { 
        deleteNote, 
        isDriver,
        isStocker,
        archiveNote 
    } = useAppContext(); 

    let date = moment(createdAt);
    date = date.format('MMM Do, YYYY ')
    return (
        <Wrapper>
            <header>
                <div className='info'>
                    <h5>Prioity: {severity}</h5>
                    <p>{noteType} : {date}</p>
                </div>
            </header>
            <div className='content'>
                <div className='content-center'>
                    <div>{newNote}</div>
                </div>
                <footer>
                    {isDriver || isStocker ? 
                        <div className='action'>
                            <button type='button' className='btn delete-btn' onClick={() => archiveNote(_id)} >
                            Archive
                            </button>
                        </div>
                    :
                        <div className='action'>
                        <button type='button' className='btn delete-btn' onClick={() => deleteNote(_id)} >
                            Delete
                        </button>
                    
                        </div>
                    }
                </footer>
            </div>
        </Wrapper>
    )
}

export default Note;