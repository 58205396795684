import React from 'react';
import { Link } from 'react-router-dom';
import Wrapper from '../assets/wrappers/MachineProduct';
import { useAppContext } from '../context/appContext';
import moment from 'moment';

const MachineProduct = ({
  _id,
  productName,
  productType,
  location,
  price,
  sales,
  maxStock,
  currentStock,
  stocked,
  stockedDate,
  row,
  column,
  rowPosition,
  columnPosition,
}) => {
  const {
    deleteMachineProduct,
    selectedId,
    selectedMachineName,
    editMachineProduct,
    editMachineProductLocation,
    isStocker,
    driverFillSelectedMachine,
    fillSelectedMachine,
    driverOtherFillAmount,
    otherFillAmount,
    setMachineStatusAll,
    isDriver,
  } = useAppContext();

  let date = moment(stockedDate);
  date = date.format('MMM Do, YYYY, h:mm:ss a ');

  const handleDelete = (e) => {
    deleteMachineProduct(selectedId, _id);
    //Window.location.reload()
  };

  const handleEdit = (e) => {
    let updatedLocation = prompt(
      'Updated Location: (Leave blank to keep Location)',
    );
    if (!updatedLocation) {
      updatedLocation = location;
    }

    let updatedPrice = prompt('Updated Price: (Leave blank to keep Price)');
    if (!updatedPrice) {
      updatedPrice = price;
    }

    let updatedMaxStock = prompt(
      'Updated Max Stock: (Leave blank to keep Max Stock',
    );
    if (!updatedMaxStock) {
      updatedMaxStock = maxStock;
    }

    editMachineProduct(
      selectedId,
      _id,
      updatedLocation,
      updatedPrice,
      updatedMaxStock,
    );
  };

  const handleMap = (e) => {
    let updatedRow = prompt('Insert Product Row position (begins at 0)');
    if (!updatedRow) {
      updatedRow = row;
    }

    let updatedColumn = prompt('Insert Product Column position (begins at 0');
    if (!updatedColumn) {
      updatedColumn = column;
    }

    editMachineProductLocation(selectedId, _id, updatedRow, updatedColumn);
  };

  const neededStock = maxStock - currentStock;

  return (
    <Wrapper>
      <header className={stocked > 0 ? 'needs-fill' : ''}>
        <div className='main-icon'>{location}</div>
        <div className='info'>
          <h5>{productName}</h5>
          <p>{productType}</p>
        </div>
      </header>
      <div className='content'>
        <div className='content-center'>
          <div>Sales: {sales}</div>
          <div>Price: ${Number(price).toFixed(2)}</div>
          <div>Max Stock: {maxStock}</div>
          <div>Current Stock: {currentStock}</div>
          {isStocker && <div>Stock Needed: {neededStock}</div>}
          {rowPosition >= 0 || columnPosition >= 0 ? (
            <div>
              Mapped: {rowPosition}:{columnPosition}
            </div>
          ) : (
            <div>Unmapped</div>
          )}
          {/* Stocked Info */}
          {stocked > 0 ? <div>Stocked: {stocked}</div> : <div></div>}
          {date && <div> Stocked Date: {date}</div>}
        </div>
        <footer>
          <div className='action'>
            <>
              {isDriver ? (
                <>
                  <button
                    type='button'
                    className='btn edit-btn'
                    onClick={() => {
                      driverFillSelectedMachine(
                        neededStock,
                        location,
                        _id,
                        selectedId,
                        productName,
                        selectedMachineName,
                      );
                    }}
                  >
                    Fill
                  </button>
                  <button
                    type='button'
                    className='btn edit-btn'
                    onClick={() => {
                      driverOtherFillAmount(
                        location,
                        _id,
                        selectedId,
                        productName,
                        selectedMachineName,
                      );
                    }}
                  >
                    Other Amount
                  </button>
                  <Link
                    to='/machine-main'
                    className='btn view-btn'
                    onClick={() => setMachineStatusAll()}
                  >
                    Replace
                  </Link>
                </>
              ) : (
                <>
                  {isStocker ? (
                    <>
                      <button
                        type='button'
                        className='btn edit-btn'
                        onClick={() => {
                          fillSelectedMachine(
                            neededStock,
                            location,
                            _id,
                            selectedId,
                            productName,
                            selectedMachineName,
                          );
                        }}
                      >
                        Fill
                      </button>
                      <button
                        type='button'
                        className='btn edit-btn'
                        onClick={() => {
                          otherFillAmount(
                            location,
                            _id,
                            selectedId,
                            productName,
                            selectedMachineName,
                          );
                        }}
                      >
                        Other Amount
                      </button>
                      <Link
                        to='/machine-main'
                        className='btn view-btn'
                        onClick={() => setMachineStatusAll()}
                      >
                        Replace
                      </Link>
                    </>
                  ) : (
                    <>
                      <button
                        type='button'
                        className='btn edit-btn'
                        onClick={handleEdit}
                      >
                        Edit
                      </button>
                      <button
                        type='button'
                        className='btn edit-btn'
                        onClick={handleMap}
                      >
                        {rowPosition >= 0 || columnPosition >= 0 ? (
                          <div>Update Map</div>
                        ) : (
                          <div>Map</div>
                        )}
                      </button>
                      <button
                        type='button'
                        className='btn delete-btn'
                        onClick={handleDelete}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </>
              )}
            </>
          </div>
        </footer>
      </div>
    </Wrapper>
  );
};

export default MachineProduct;
