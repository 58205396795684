import React from 'react'
import { useAppContext } from '../context/appContext'
import Loading from './Loading'
import MachineProduct from './MachineProduct'
import MachineProductExtraSmall from './MachineProductExtraSmall'
import SelectedProductInfoContainer from './SelectedProductInfoContainer'

const SelectedProductsContainer = (
    selectedRows
) => {

    const { 
        isLoading,
        gridView,
        selectedColumns
     } = useAppContext()

    if (isLoading) {
        return <Loading center />
    }

    const columns = selectedColumns

    let productsSmall

    switch (selectedColumns) {
        case 3:
            productsSmall = 'products-small-3'
            break
        case 4:
            productsSmall = 'products-small-4'
            break
        case 5:
            productsSmall = 'products-small-5'
            break
        case 6:
            productsSmall = 'products-small-6'
            break
        case 7:
            productsSmall = 'products-small-7'
            break
        case 8:
            productsSmall = 'products-small-8'
            break
        case 9:
            productsSmall = 'products-small-9'
            break
        case 10:
            productsSmall = 'products-small-10'
            break
    }

    const { machineProducts } = selectedRows

    if (!machineProducts) {
        return <>
            <h2>No products in machine...</h2>
        </>
    }

    function compare( a, b ) {
        if ( a.location < b.location ){
          return -1;
        }
        if ( a.location > b.location ){
          return 1;
        }
        return 0;
      }

    return (
        <>
            <h5>
                {machineProducts.length} Product{machineProducts.length > 1 && 's'}
            </h5>

            {gridView &&
                <div className='sticky-component'>
                    {<SelectedProductInfoContainer/>}
                    <br></br>
                    <br></br>
                </div>
            }

            {gridView ? 
                <div className={`${productsSmall} scrollable-container`} >
                    {machineProducts.sort(compare).map((row) => {
                        return <MachineProductExtraSmall key={row._id} {...row} />
                    })}
                </div>
                :
                <div className='products'>
                    {machineProducts.sort(compare).map((row) => {
                        return <MachineProduct key={row._id} {...row} />
                    })}
                </div>
            }
        </>
    )
}

export default SelectedProductsContainer;