import React from 'react';
import { FormRow, FormRowSelect, FormRowStep, Alert } from '../../components';
import { useAppContext } from '../../context/appContext';
import { Navigate } from 'react-router-dom';

import Wrapper from '../../assets/wrappers/DashboardFormPage';

const AddProduct = () => {
  const {
    isLoading,
    isEditing,
    showAlert,
    displayAlert,
    handleChange,
    clearValues,
    productName,
    productType,
    productTypeOptions,
    category,
    categoryOptions,
    supplierOptions,
    vending,
    caseCost,
    caseQty,
    supplier,
    createProduct,
    editProduct,
    user,
  } = useAppContext();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !productName ||
      !productType ||
      !vending ||
      !caseCost ||
      !caseQty ||
      !supplier
    ) {
      displayAlert();
      return;
    }

    if (isEditing) {
      editProduct();
      return;
    }
    createProduct();
  };

  const handleProductInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });
  };

  if (
    user.role === 'stocker' ||
    user.role === 'driver' ||
    user.role === 'stocker-driver'
  ) {
    return <Navigate to='/' />;
  }

  return (
    <Wrapper>
      <form className='form'>
        <h3>{isEditing ? 'edit product' : 'add product'}</h3>
        {showAlert && <Alert />}
        <div className='form-center'>
          {/* productName */}
          <FormRow
            labelText='Product Name'
            type='text'
            name='productName'
            value={productName}
            handleChange={handleProductInput}
          />
          {/* productType */}
          <FormRowSelect
            name='productType'
            value={productType}
            labelText='Product Type'
            handleChange={handleProductInput}
            list={productTypeOptions}
          />
          {/* category */}
          <FormRowSelect
            name='category'
            value={category}
            labelText='Category'
            handleChange={handleProductInput}
            list={categoryOptions}
          />
          {/* vending */}
          <FormRowStep
            labelText='Price'
            type='number'
            name='vending'
            value={vending}
            step='0.05'
            handleChange={handleProductInput}
          />
          {/* caseCost */}
          <FormRowStep
            labelText='Case Cost'
            type='number'
            name='caseCost'
            value={caseCost}
            step='0.01'
            handleChange={handleProductInput}
          />
          {/* caseQty */}
          <FormRowStep
            labelText='Case Quantity'
            type='number'
            name='caseQty'
            value={caseQty}
            step='1'
            handleChange={handleProductInput}
          />
          {/* supplier */}
          <FormRowSelect
            name='supplier'
            value={supplier}
            handleChange={handleProductInput}
            list={supplierOptions}
          />
          {/* btn Container */}
          <div className='btn-container'>
            <button
              type='submit'
              className='btn btn-block sumbit-btn'
              onClick={handleSubmit}
              disabled={isLoading}
            >
              submit
            </button>
            <button
              className='btn btn-block clear-btn'
              onClick={(e) => {
                e.preventDefault();
                clearValues();
              }}
            >
              clear
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default AddProduct;
