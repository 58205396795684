import React from 'react';
import { FormRow, FormRowSelect } from '.'
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/SearchContainer';

const  SelectedNotesContainer = () => {

    const {
        isLoading,
        newNote,
        noteType,
        severity,
        noteTypeOptions,
        severityTypeOptions,
        handleChange,
        displayAlert,
        clearValues,
        createNote
    } = useAppContext();

    const handleNoteInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        handleChange({ name, value });
      }

      const handleSubmit = (e) => {
        e.preventDefault();

        if (!newNote || !noteType || !severity ) {
            displayAlert();
            return;
        }

        createNote();
    }

    return (
        <Wrapper>
            <form className='form'>
                <h4>Add a note</h4>
                <div className='form-center'>
                    {/* new note */}
                    <FormRow
                        type='text'
                        name='newNote'
                        value={newNote}
                        handleChange={handleNoteInput}
                    ></FormRow>
                    {/* note type */}
                    <FormRowSelect
                        labelText='note type'
                        name='noteType'
                        value={noteType}
                        handleChange={handleNoteInput}
                        list={['all', ...noteTypeOptions]}
                    ></FormRowSelect>
                    {/* severity */}
                    <FormRowSelect
                        name='severity'
                        value={severity}
                        handleChange={handleNoteInput}
                        list={[...severityTypeOptions]}
                    ></FormRowSelect>
                    {/* btn Container */}
                    <div className='btn-container'>
                        <button 
                            type='submit' 
                            className='btn sumbit-btn' 
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            submit
                        </button>
                        <button 
                            className='btn clear-btn' 
                            onClick={(e) => {
                                e.preventDefault();
                                clearValues();
                            }}
                            >
                                clear
                            </button>
                    </div>
                </div>
            </form>
        </Wrapper>
    )
}

export default SelectedNotesContainer;