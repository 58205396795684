import React, { useEffect } from 'react';
import { useAppContext } from '../../context/appContext';
import SelectedMachine from '../../components/SelectedMachine';
import Wrapper from '../../assets/wrappers/ProductsContainer';

const MachineStocker = ({

}) => {

    const { 
        selectedId,
        selectedMachineName,
        selectedMachineType,
        selectedStatus,
        selectedActivity,
        selectedRegion,
        selectedCountry,
        selectedUsState,
        selectedCity,
        selectedZip,
        selectedAddress,
        selectedNote,
        selectedTopSeller,
        selectedServicedOn,
        selectedServicedBy,
        selectedRows,
        selectedColumns,
        getStockerProducts,
        // getProducts,
        // products,
        // totalProducts,
        // page, 
        // search,
        // searchStatus,
        // searchType,
        // sort
        stockerProductNameSearch, 
        stockerProductTypeSearch, 
        stockerProductSortSearch, 
        stockerProductPageSearch,
     } = useAppContext();

     useEffect(() => {
        getStockerProducts();
        // eslint-disable-next-line
    }, [ stockerProductNameSearch, stockerProductTypeSearch, stockerProductSortSearch, stockerProductPageSearch ])

    return (
        <Wrapper>
            <SelectedMachine machine={{ 
                selectedId,
                selectedMachineName,
                selectedMachineType,
                selectedStatus,
                selectedActivity,
                selectedRegion,
                selectedCountry,
                selectedUsState,
                selectedCity,
                selectedZip,
                selectedAddress,
                selectedNote,
                selectedTopSeller,
                selectedServicedOn,
                selectedServicedBy,
                selectedRows,
                selectedColumns
            }}/>
        </Wrapper>
    )
};

export default MachineStocker;
