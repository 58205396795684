import React, { useState } from 'react'
import { FormRowSelect } from '.'
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/FileUpload';

const MonthlyTransactions = () => {

    const {
        isLoading,
        getMonthlyTransactionsReport
    } = useAppContext();

    const currentYear = new Date().getFullYear();

    const getCurrentMonth = () => {
        const months = [
          'January', 'February', 'March', 'April',
          'May', 'June', 'July', 'August',
          'September', 'October', 'November', 'December'
        ];
      
        const currentDate = new Date();
        const currentMonthIndex = currentDate.getMonth();
        const currentMonth = months[currentMonthIndex];
      
        return currentMonth;
      };

    const getNextFiveYears = () => {
        const currentYear = new Date().getFullYear();
        const nextFiveYears = [];
        nextFiveYears.push((currentYear -1).toString()); //Add previous year
      
        for (let i = 0; i < 5; i++) {
          nextFiveYears.push((currentYear + i).toString());
        }
      
        return nextFiveYears;
      }
      
      const yearsArray = getNextFiveYears();


    const [formData, setFormData] = useState({
        month: getCurrentMonth(),
        year: currentYear.toString(),
        searchType: 'Sales By Machine',
      });

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

    const onSubmit = async (e) => {
        e.preventDefault();

        
        console.log('Form submitted:', formData);
        getMonthlyTransactionsReport(formData.month, formData.year, formData.searchType);

    }

    return (
        <Wrapper>
            <form className='form' onSubmit={onSubmit}>
            <h4>search monthly transactions</h4>
                <div className='form-center'>
                    {/* month */}
                    <FormRowSelect
                        name='month'
                        value={formData.month}
                        handleChange={handleInputChange}
                        list={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                    ></FormRowSelect>
                    {/* year */}
                    <FormRowSelect
                        name='year'
                        value={formData.year}
                        handleChange={handleInputChange}
                        list={yearsArray}
                    ></FormRowSelect>
                    {/* search type */}
                    <FormRowSelect
                        labelText='search type'
                        name='searchType'
                        value={formData.searchType}
                        handleChange={handleInputChange}
                        list={['Sales By Machine', 'Sales By Product By Machine', 'Sales By Product']}
                    ></FormRowSelect>
                    <button className='btn btn-block btn-danger' disabled={isLoading} onClick={onSubmit}>
                        Get Report
                    </button>
                </div>
            </form>
      </Wrapper>
    )
}

export default MonthlyTransactions