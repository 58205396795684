import React from "react";

const FormRowStep = ({ type, name, value, handleChange, labelText, step}) => {
    return (
        <div className='form-row'>
                <label htmlFor={name} className='form-label'>
                    {labelText || name}
                </label>
                <input 
                    type={type} 
                    value={value} 
                    name={name}
                    onChange={handleChange}
                    className='form-input'
                    step={step}
                />    
            </div>
    )
}

export default FormRowStep;