import React, {Fragment} from 'react';
import links from '../utils/links';
import { NavLink } from 'react-router-dom';

import { useAppContext } from '../context/appContext';

const NavLinks = ({ toggleSidebar }) => {

    const { user } = useAppContext();

    const userRole = user.role

    return (
        <div className='nav-links'>
                        {links.map((link) => {
                            const { text, path, id, icon, role } = link;

                            let userRoleCheck = false

                            if (userRole === 'super-admin') {
                                userRoleCheck = true
                            } else if (userRole === 'admin') {
                                if (role <= 4 ) {
                                    userRoleCheck = true
                                }
                            } else if (userRole === 'stocker-driver') { 
                                if (role <= 3) {
                                    userRoleCheck = true
                                }
                            } else if (userRole === 'stocker') {
                                if (role === 0 || role === 1) {
                                    userRoleCheck = true
                                }

                            } else if (userRole === 'driver') {
                                if (role === 0 || role === 2) {
                                    userRoleCheck = true
                                }
                            }

                            return (
                                <Fragment key={id}>
                                    {userRoleCheck ?  
                                        (
                                            <NavLink
                                                to={path}
                                                key={id}
                                                onClick={toggleSidebar}
                                                className={({isActive}) => isActive ? 'nav-link active' : 'nav-link' }>
                                                <span className='icon'>{icon}</span>
                                                {text}
                                            </NavLink>
                                        ) : (<></>) 
                                }
                                </Fragment>
                            )
                        })}
                    </div>
    )
};

export default NavLinks;