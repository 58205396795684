import React from 'react';
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/Job';

const SelectedUserInfo = ({
    user
}) => {

    const { 
        selectedUserId, 
        selectedUserName, 
        selectedLastName, 
        selectedUserEmail, 
        selectedRole, 
        selectedUserStatus,
      } = user

    const { setEditUser, deleteUser, toggleUserActions, showActions } = useAppContext();   

  return (
    <Wrapper>
    <header>
        <div className='main-icon'>
            {selectedUserName.charAt(0)}
        </div>
        <div className='info'>
            <h5>{selectedUserName} {selectedLastName}</h5>
            <p>{selectedRole}</p>
            <p>{selectedUserStatus ? 'active' : 'inactive'}</p>
        </div>
    </header>
    <div className='content'>
        <div className='content-center'>
            <div>Email: {selectedUserEmail}</div>
        </div>
        <footer>
            <div className='action'>
                <button type='button' className={showActions ? 'btn hide-btn' : 'btn view-btn'} onClick={() => toggleUserActions(selectedUserId)} >
                    {showActions ? 'Hide Actions' : 'View Actions'}
                </button>    
            </div>
        </footer>
    </div>
    </Wrapper>
  )
}

export default SelectedUserInfo