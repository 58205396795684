import React, { useEffect } from 'react';
import { useAppContext } from '../context/appContext';
import StatItem from './StatItem';
import { FaBoxOpen, FaCalendarCheck, FaBoxes } from 'react-icons/fa';
import Wrapper from '../assets/wrappers/StatsContainer';

const StockedAndDelivered = () => {
  const { stocked, delivered, needsStock, getStockedAndDelivered } =
    useAppContext();

  useEffect(() => {
    getStockedAndDelivered();
    // eslint-disable-next-line
  }, []);

  const defaultStats = [
    {
      title: 'Needs Stock',
      count: needsStock || 0,
      icon: <FaBoxOpen />,
      color: '#32a852',
      bcg: '#fcefc7',
    },
    {
      title: 'Stocked',
      count: stocked || 0,
      icon: <FaBoxes />,
      color: '#e9b949',
      bcg: '#fcefc7',
    },
    {
      title: 'Delivered',
      count: delivered || 0,
      icon: <FaCalendarCheck />,
      color: '#647acb',
      bcg: '#e0e8f9',
    },
  ];

  return (
    <Wrapper>
      {defaultStats.map((item, index) => {
        return <StatItem key={index} {...item} />;
      })}
    </Wrapper>
  );
};

export default StockedAndDelivered;
