import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Register, Landing, Error, ProtectedRoute } from './pages';
import { 
  AddProduct, 
  AllProducts, 
  Profile, 
  SharedLayout, 
  Dashbaord,
  AddMachine,
  AddUser,
  AllMachines,
  AllUsers,
  UserMain,
  MachineMain,
  Driver,
  Stocker,
  MachineStocker,
  MachineDriver
} from './pages/dashboard';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path='/' 
          element={
            <ProtectedRoute> 
              <SharedLayout />
            </ProtectedRoute>
          } 
        >
          <Route index element={<Dashbaord/>}/>

          <Route path='all-products' element={<AllProducts/>}/>
          <Route path='add-product' element={<AddProduct/>}/>

          <Route path='all-machines' element={<AllMachines/>}/>
          <Route path='add-machine' element={<AddMachine/>}/>

          <Route path='all-users' element={<AllUsers/>}/>
          <Route path='add-user' element={<AddUser/>}/>
          <Route path='user-main' element={<UserMain/>}/>
          
          <Route path='profile' element={<Profile/>}/>

          <Route path='machine-main' element={<MachineMain/>}/>

          <Route path='machine-stocker' element={<MachineStocker/>}/>

          <Route path='machine-driver' element={<MachineDriver/>}/>

          <Route path='driver' element={<Driver/>}/>
          <Route path='stocker' element={<Stocker/>}/>
        </Route>  
        <Route path='/register' element={<Register />} /> 
        <Route path='/landing' element={<Landing />} /> 
        <Route path='*' element={<Error />} />  
      </Routes>
    </BrowserRouter>
  );
};

export default App;
