import React from 'react';
import { FormRow, FormRowSelect } from '.'
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/SearchContainer';

const  MachinesSearchContainer = () => {

    const {
        isLoading,
        search,
        region,
        regionOptions,
        searchType,
        sort,
        sortOptions,
        activeInactive,
        activityOptions,
        machineStatus,
        machineStatusOptions,
        machineTypeOptions,
        handleChange,
        clearFilters
    } = useAppContext();

    const handleSearch = (e) => {
        if (isLoading) return 
        handleChange({ name: e.target.name, value: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        clearFilters();
    }

    return (
        <Wrapper>
            <form className='form'>
                <h4>search form</h4>
                <div className='form-center'>
                    {/* search machineName */}
                    <FormRow
                        type='text'
                        name='search'
                        value={search}
                        handleChange={handleSearch}
                    ></FormRow>
                    {/* search by machine type */}
                    <FormRowSelect
                        labelText='machine type'
                        name='searchType'
                        value={searchType}
                        handleChange={handleSearch}
                        list={['all', ...machineTypeOptions]}
                    ></FormRowSelect>
                    {/* sort */}
                    <FormRowSelect
                        name='sort'
                        value={sort}
                        handleChange={handleSearch}
                        list={sortOptions}
                    ></FormRowSelect>
                    {/* machine activity */}
                    <FormRowSelect
                        labelText='activity'
                        name='activeInactive'
                        value={activeInactive}
                        handleChange={handleSearch}
                        list={activityOptions}
                    ></FormRowSelect>
                    {/* machine status */}
                    <FormRowSelect
                        labelText='machine status'
                        name='machineStatus'
                        value={machineStatus}
                        handleChange={handleSearch}
                        list={machineStatusOptions}
                    ></FormRowSelect>
                    {/* region */}
                    <FormRowSelect
                        labelText='region'
                        name='region'
                        value={region}
                        handleChange={handleSearch}
                        list={regionOptions}
                    ></FormRowSelect>
                    <button className='btn btn-block btn-danger' disabled={isLoading} onClick={handleSubmit}>
                        clear filters
                    </button>
                </div>
            </form>
        </Wrapper>
    )
}

export default MachinesSearchContainer;