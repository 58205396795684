import React from 'react';
import Wrapper from '../assets/wrappers/PercentageBar';

const PercentageBar = ({ percentage }) => {
  let colorClass;
  let textColorClass;

  if (percentage >= 80) {
    colorClass = 'green';
    textColorClass = 'white';
  } else if (percentage >= 60) {
    colorClass = 'orange';
    textColorClass = 'white';
  } else {
    colorClass = 'red';
    textColorClass = 'black';
  }

  return (
    <Wrapper>
      <div className='percentage-bar'>
        <div
          className={`percentage-bar-fill ${colorClass}`}
          style={{ width: `${percentage}%` }}
        />
        <span className={`percentage-text ${textColorClass}`}>
          {percentage}%
        </span>
      </div>
    </Wrapper>
  );
};

export default PercentageBar;
