import React, { useEffect } from 'react';
import { useAppContext } from '../../context/appContext';
import { Navigate } from "react-router-dom";

import { MachinesContainer, MachinesSearchContainer } from '../../components';

const AllMachines = () => {

    const { 
        setMachineStatusAll,
        user,
        clearSelectedProduct
      } = useAppContext();

      useEffect(() => {
        setMachineStatusAll()
        clearSelectedProduct()
        // eslint-disable-next-line
    }, [])  

    if (user.role === 'stocker' || user.role === 'driver' || user.role === 'stocker-driver') {
        return <Navigate to="/"/>
    }

    return (
        <>
            <MachinesSearchContainer />
            <MachinesContainer />
        </>
    )
};

export default AllMachines;
