import React from 'react'

import { Navigate } from "react-router-dom"
import { useAppContext } from '../context/appContext'

import SelectedProductsContainer from './SelectedProductsContainer'
import SelectedMachineInfo from './SelectedMachineInfo'
import SelectedAddNotesContainer from './SelectedAddNotesContainer'
import SelectedNotesContainer from './SelectedNotesContainer'
import DriverSelectedMachine from './DriverSelectedMachine'
import StockerSelectedMachine from './StockerSelectedMachine'

const  SelectedMachine = ({  
    machine
}) => {

    const { 
        selectedRows,
    } = machine

    const { 
        showMachineProduct, 
        showMachineNote, 
        showMachineAddNote, 
        selectedId, 
        isLoading, 
        isStocker, 
        isDriver 
    } = useAppContext()

    return (
        <>
        {!selectedId && !isLoading ? <Navigate to="/all-machines" /> : 
            <>
                {isDriver ? 
                <> 
                    <DriverSelectedMachine machineProducts={selectedRows} machine={machine}/>
                </> : <>
                    {isStocker ?
                        <> 
                            <StockerSelectedMachine machineProducts={selectedRows} machine={machine}/>
                        </>
                        :
                        <>
                            <SelectedMachineInfo machine={machine} isStocker={isStocker} isDriver={isDriver}/>
                            <br></br>
                            {showMachineAddNote &&
                                <SelectedAddNotesContainer />    
                            }
                            {showMachineNote &&
                                <SelectedNotesContainer />
                            }
                            {showMachineProduct &&
                                <SelectedProductsContainer machineProducts={selectedRows}/>
                            }
                        </>
                    }
                    </>
                    
                }               
            </> 
        } 
    </>
)}

export default SelectedMachine;