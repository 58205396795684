import React from 'react';
import { useAppContext } from '../context/appContext';
import SelectedUserInfo from './SelectedUserInfo';
import UserActionsContainer from './UserActionsContainer';


const SelectedUser = ({
    user: selectedUser
}) => {

    const { setEditUser, deleteUser, toggleUserActions, showActions, user, updateUserStatus, addExtendedRegistrationDate } = useAppContext();     

    const userRole = user.role

  return (
    <>
    
        <SelectedUserInfo user={selectedUser} />
        <br></br>
        {showActions &&
            <UserActionsContainer />    
        }
        {userRole && userRole.includes('admin') ? (
            <>
              {<button type='button' className={'btn view-btn'} onClick={() => updateUserStatus(selectedUser.selectedUserId, selectedUser.selectedUserStatus)}> 
                {selectedUser.selectedUserStatus ? 'Deactivate account' : 'Activate Account'}
              </button>}

              {<button type='button' className={'btn view-btn'} onClick={() => addExtendedRegistrationDate(selectedUser.selectedUserId)}> 
                Extend Registration Date
              </button>}
            </>
          ) : (
            null
          )}
        
    
    </>
  )
}

export default SelectedUser