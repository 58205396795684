import styled from 'styled-components'

const Wrapper = styled.article`
.action-center {
    border-style: solid;
    text-align: center;
}

.action-center button{
    margin: 10px;
}

.alert-center {
    border-style: solid;
    text-align: center;
}

.alert-center button {
    margin: 10px;
}
`


export default Wrapper