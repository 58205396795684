import styled from 'styled-components'

const Wrapper = styled.article`
    .messages {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }
  
    .messages td, .messages th {
        border: 1px solid #ddd;
        padding: 8px;
    }
  
    .messages tr:nth-child(even){background-color: #f2f2f2;}

    .messages button:hover {background-color: ##99ccff;}
  
    .messages th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #3399ff;
        color: white;
    }
`

export default Wrapper