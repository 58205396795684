import React from 'react';
import { useAppContext } from '../../context/appContext';
import { Navigate } from "react-router-dom";

import SelectedUser from '../../components/SelectedUser';
import Wrapper from '../../assets/wrappers/JobsContainer';

const UserMain = () => {

    const { 
        selectedUserId, 
        selectedUserName,
        selectedUserEmail,
        selectedLastName,
        selectedRole,
        selectedCreatedAt,
        selectedUserStatus,
        getActions,
        user,
     } = useAppContext();

    if (user.role === 'stocker' || user.role === 'driver' || user.role === 'stocker-driver') {
        return <Navigate to="/"/>
    } 

    return (
        <Wrapper>
            <SelectedUser user={{ 
                selectedUserId, 
                selectedUserName,
                selectedUserEmail,
                selectedLastName,
                selectedRole,
                selectedCreatedAt,
                selectedUserStatus,
            }}/>
        </Wrapper>
    )
};

export default UserMain;