import React from 'react';
import { useAppContext } from '../context/appContext';
import Loading from './Loading';
import StockerProduct from './StockerProduct';
import Wrapper from '../assets/wrappers/StockerProductsContainer';
import PageBtnContainer from './PageBtnContainer';

const StockerProductsContainer = (
    
) => {
    const { 
        stockerProducts, 
        isLoading, 
        numOfStockerPages
     } = useAppContext()

    if (isLoading) {
        return <Loading center />
    }

    if (stockerProducts.length === 0) {
        return <Wrapper>
            <h2>No products to display...</h2>
        </Wrapper>
    }

    return (
        <Wrapper>
       <h5>
            </h5>
            <div className='items'>
                {stockerProducts.map((product) => {
                    return <StockerProduct key={product._id} {...product} />
                })}
            </div>
        </Wrapper>
    )
}

export default StockerProductsContainer;