import React from 'react'
import { FormRow, FormRowSelect, Alert } from './';
import { useAppContext } from '../context/appContext';

import Wrapper from '../assets/wrappers/DashboardFormPage';

const AddMessage = ({ users }) => {
    const { 
        isLoading,
        showAlert, 
        displayAlert, 
        recieverName,
        messageType,
        messageTypeOptions,
        message,
        handleChange,
        clearValues,
        createMessage,
      } = useAppContext();

      const userNameList = users.map((user) => {
        return (
            `${user.name} ${user.lastName}`
        )
    })

      const handleSubmit = (e) => {
          e.preventDefault();

          if (!recieverName || !messageType || !message) {
              displayAlert();
              return;
          }
          
          createMessage();
      }

      const handleMessageInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        handleChange({ name, value });
      }

    return (
        <Wrapper>
            <form className='form'>
                {showAlert && <Alert />}
                <div className='form-center'>
                    {/* recieverName */}
                    <FormRowSelect
                        type='text' 
                        name='recieverName' 
                        labelText='to'
                        value={recieverName}
                        handleChange={handleMessageInput}
                        list={['', ...userNameList]}
                    />
                    {/* messageType */}
                    <FormRowSelect 
                        type='text' 
                        name='messageType' 
                        labelText='message type'
                        value={messageType}
                        handleChange={handleMessageInput}
                        list={['', ...messageTypeOptions]}
                    />
                    {/* message */}
                    <FormRow 
                        type='text' 
                        name='message' 
                        value={message}
                        handleChange={handleMessageInput}
                    />
                    {/* btn Container */}
                    <div className='btn-container'>
                        <button 
                            type='submit' 
                            className='btn btn-block sumbit-btn' 
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            submit
                        </button>
                        <button 
                            className='btn btn-block clear-btn' 
                            onClick={(e) => {
                                e.preventDefault();
                                clearValues();
                            }}
                            >
                                clear
                            </button>
                    </div>

                </div>
            </form>
        </Wrapper>
    )
}

export default AddMessage