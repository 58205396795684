import {
  //Alerts
  CLEAR_ALERT,
  DISPLAY_ALERT,
  DISPLAY_CUSTOM_ALERT,
  CLEAR_CUSTOM_ALERT,
  GET_ALERTS_BEGIN,
  GET_ALERTS_SUCCESS,
  GET_ALERTS_ERROR,
  ARCHIVE_ALERT_BEGIN,
  ARCHIVE_ALERT_SUCCESS,
  ARCHIVE_ALERT_ERROR,
  //User
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  SETUP_USER_ERROR,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  HANDLE_CHANGE,
  CLEAR_VALUES,
  CREATE_JOB_BEGIN,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_ERROR,
  GET_JOBS_BEGIN,
  GET_JOBS_SUCCESS,
  SET_EDIT_JOB,
  DELETE_JOB_BEGIN,
  EDIT_JOB_BEGIN,
  EDIT_JOB_SUCCESS,
  EDIT_JOB_ERROR,
  SHOW_STATS_BEGIN,
  SHOW_STATS_SUCCESS,
  CLEAR_FILTERS,
  CHANGE_PAGE,
  //Product
  CREATE_PRODUCT_BEGIN,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_ERROR,
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  SET_EDIT_PRODUCT,
  EDIT_PRODUCT_BEGIN,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_ERROR,
  DELETE_PRODUCT_BEGIN,
  DOWNLOAD_PRODUCTS_BEGIN,
  DOWNLOAD_PRODUCTS_SUCCESS,
  DOWNLOAD_PRODUCTS_ERROR,
  //Machine
  CREATE_MACHINE_BEGIN,
  CREATE_MACHINE_SUCCESS,
  CREATE_MACHINE_ERROR,
  GET_MACHINES_BEGIN,
  GET_MACHINES_SUCCESS,
  SET_EDIT_MACHINE,
  EDIT_MACHINE_BEGIN,
  EDIT_MACHINE_SUCCESS,
  EDIT_MACHINE_ERROR,
  DELETE_MACHINE_BEGIN,
  EDIT_MACHINE_ACTIVITY,
  SET_MACHINE_BEGIN,
  SET_MACHINE_SUCCESS,
  SET_MACHINE_ERROR,
  SET_ADD_MACHINE_PRODUCT,
  REMOVE_SELECTED_MACHINE,
  ADD_MACHINE_PRODUCT_BEGIN,
  ADD_MACHINE_PRODUCT_SUCCESS,
  ADD_MACHINE_PRODUCT_ERROR,
  DELETE_MACHINE_PRODUCT,
  TOGGLE_MACHINE_PRODUCT,
  TOGGLE_MACHINE_NOTE,
  TOGGLE_MACHINE_ADD_NOTE,
  SET_MACHINE_STATUS,
  EDIT_MACHINE_PRODUCT_BEGIN,
  EDIT_MACHINE_PRODUCT_SUCCESS,
  EDIT_MACHINE_PRODUCT_ERROR,
  EDIT_CURRENT_STOCK_BEGIN,
  EDIT_CURRENT_STOCK_SUCCESS,
  EDIT_CURRENT_STOCK_ERROR,
  EDIT_MACHINE_PRODUCT_LOCATION_BEGIN,
  EDIT_MACHINE_PRODUCT_LOCATION_SUCCESS,
  EDIT_MACHINE_PRODUCT_LOCATION_ERROR,
  FILL_SELECTED_MACHINE_BEGIN,
  FILL_SELECTED_MACHINE_SUCCESS,
  FILL_SELECTED_MACHINE_ERROR,
  UPDATE_MACHINE_STATUS,
  DRIVER_FILL_SELECTED_MACHINE_BEGIN,
  DRIVER_FILL_SELECTED_MACHINE_SUCCESS,
  DRIVER_FILL_SELECTED_MACHINE_ABORT,
  DRIVER_FILL_SELECTED_MACHINE_ERROR,
  DRIVER_REPLACE_ITEM_FLAG_BEGIN,
  DRIVER_REPLACE_ITEM_FLAG_SUCCESS,
  DRIVER_REPLACE_ITEM_FLAG_ERROR,
  UPDATE_GRID_VIEW,
  UPDATE_PRODUCT_INFO_VIEW,
  SHOW_HIDE_PRODUCT_INFO_VIEW,
  CLEAR_SELECTED_PRODUCT,
  TOGGLE_STOCKER_OTHER_AMOUNT,
  TOGGLE_DRIVER_OTHER_AMOUNT,
  TOGGLE_INDEPENDENT_PRICING_BEGIN,
  TOGGLE_INDEPENDENT_PRICING_SUCCESS,
  TOGGLE_INDEPENDENT_PRICING_ERROR,
  //User
  CREATE_USER_BEGIN,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  GET_USERS_BEGIN,
  GET_USERS_SUCCESS,
  SET_EDIT_USER,
  EDIT_USER_BEGIN,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  DELETE_USER_BEGIN,
  TOGGLE_USER_ACTIONS,
  GET_ACTIONS_BEGIN,
  GET_ACTIONS_SUCCESS,
  SET_USER_BEGIN,
  SET_USER_SUCCESS,
  SET_USER_ERROR,
  TOGGLE_USER_STATUS_BEGIN,
  TOGGLE_USER_STATUS_SUCCESS,
  TOGGLE_USER_STATUS_ERROR,
  ADD_EXTENDED_REGISTRATION_DATE_BEGIN,
  ADD_EXTENDED_REGISTRATION_DATE_SUCCESS,
  ADD_EXTENDED_REGISTRATION_DATE_ERROR,
  //Note
  CREATE_NOTE_BEGIN,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_ERROR,
  GET_NOTES_BEGIN,
  GET_NOTES_SUCCESS,
  DELETE_NOTE_BEGIN,
  ARCHIVE_NOTE_BEGIN,
  //File Upload & Transactions
  TOGGLE_FILE_UPLOAD,
  FILE_UPLOAD_BEGIN,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_ERROR,
  PROCESS_TRANSACTIONS_BEGIN,
  PROCESS_TRANSACTIONS_SUCCESS,
  PROCESS_TRANSACTIONS_ERROR,
  GET_DAILY_TRANSACTIONS_BEGIN,
  GET_DAILY_TRANSACTIONS_SUCCESS,
  GET_DAILY_TRANSACTIONS_ERROR,
  REMOVE_TRANSACTION_FILE_BEGIN,
  REMOVE_TRANSACTION_FILE_SUCCESS,
  REMOVE_TRANSACTION_FILE_ERROR,
  //Stocked and Delivered
  GET_STOCKED_DELIVERED_BEGIN,
  GET_STOCKED_DELIVERED_SUCCESS,
  GET_STOCKED_DELIVERED_ERROR,
  //Error Report
  TOGGLE_ERROR_REPORT,
  GET_ERROR_REPORT_BEGIN,
  GET_ERROR_REPORT_SUCCESS,
  GET_ERROR_REPORT_ERROR,
  //Transaction Report
  TOGGLE_TRANSACTION_REPORT,
  GET_TRANSACTION_REPORT_BEGIN,
  GET_TRANSACTION_REPORT_SUCCESS,
  GET_TRANSACTION_REPORT_ERROR,
  //Monthly Transactions
  GET_MONTHLY_TRANSACTIONS_BEGIN,
  GET_MONTHLY_TRANSACTIONS_SUCCESS,
  GET_MONTHLY_TRANSACTIONS_ERROR,
  TOGGLE_MONTHY_TRANSACTIONS,
  GET_MONTHLY_TRANSACTIONS_REPORT_BEGIN,
  GET_MONTHLY_TRANSACTIONS_REPORT_SUCCESS,
  GET_MONTHLY_TRANSACTIONS_REPORT_ERROR,
  //Message
  CREATE_MESSAGE_BEGIN,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_ERROR,
  //Show Message
  TOGGLE_SHOW_MESSAGE,
  //Get Messages
  GET_MESSAGES_BEGIN,
  GET_MESSAGES_SUCCESS,
  //Mark message as read
  MARK_MESSAGE_AS_READ_BEGIN,
  MARK_MESSAGE_AS_READ_SUCCESS,
  MARK_MESSAGE_AS_READ_ERROR,
  //Admin Override
  CREATE_ADMIN_OVERRIDE_BEGIN,
  CREATE_ADMIN_OVERRIDE_SUCCESS,
  CREATE_ADMIN_OVERRIDE_ERROR,
  UPDATE_ADMIN_OVERRIDE_BEGIN,
  UPDATE_ADMIN_OVERRIDE_SUCCESS,
  UPDATE_ADMIN_OVERRIDE_ERROR,
  GET_ADMIN_OVERRIDE_BEGIN,
  GET_ADMIN_OVERRIDE_SUCCESS,
  GET_ALLOWED_MACHINES_BEGIN,
  GET_ALLOWED_MACHINES_SUCCESS,
  GET_ALLOWED_MACHINES_ERROR,
  UPDATE_ALLOWED_MACHINES_BEGIN,
  UPDATE_ALLOWED_MACHINES_SUCCESS,
  UPDATE_ALLOWED_MACHINES_ERROR,
  GET_ACTIVE_MACHINES_BEGIN,
  GET_ACTIVE_MACHINES_SUCCESS,
  GET_ACTIVE_MACHINES_ERROR,
  //Stock Check
  RUN_STOCK_CHECK_BEGIN,
  RUN_STOCK_CHECK_SUCCESS,
  RUN_STOCK_CHECK_ERROR,
  //Stocker Replace Product
  STOCKER_REPLACE_PRODUCT_BEGIN,
  STOCKER_REPLACE_PRODUCT_SUCCESS,
  STOCKER_REPLACE_PRODUCT_ERROR,
  TOGGLE_STOCKER_REPLACE,
  STOCKER_SELECT_REPLACE_PRODUCT,
  //Driver Replace Product
  TOGGLE_DRIVER_REPLACE,
  DRIVER_REPLACE_PRODUCT_BEGIN,
  DRIVER_REPLACE_PRODUCT_SUCCESS,
  DRIVER_REPLACE_PRODUCT_ERROR,
  //Stocker Search Products
  STOCKER_SEARCH_PRODUCTS,
  GET_STOCKER_PRODUCTS_BEGIN,
  GET_STOCKER_PRODUCTS_SUCCESS,
  //Special
  UPDATE_PRODUCT_IDS_BEGIN,
  UPDATE_PRODUCT_IDS_SUCCESS,
  UPDATE_PRODUCT_IDS_ERROR,
  VERIFY_USER_ROLE_BEGIN,
  VERIFY_USER_ROLE_SUCCESS,
  VERIFY_USER_ROLE_ERROR,
  //Theme
  TOGGLE_THEME_BEGIN,
  TOGGLE_THEME_SUCCESS,
  TOGGLE_THEME_ERROR,
} from './actions';

import { initialState } from './appContext';

const reducer = (state, action) => {
  if (action.type === DISPLAY_ALERT) {
    return {
      ...state,
      showAlert: true,
      alertType: 'danger',
      alertText: 'Please provide all values!',
    };
  }

  if (action.type === DISPLAY_CUSTOM_ALERT) {
    return {
      ...state,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.alert,
    };
  }

  if (action.type === CLEAR_ALERT) {
    return {
      ...state,
      showAlert: false,
      alertType: '',
      alertText: '',
    };
  }

  if (action.type === CLEAR_CUSTOM_ALERT) {
    return {
      ...state,
      showAlert: false,
      alertType: '',
      alertText: '',
    };
  }

  if (action.type === SETUP_USER_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === SETUP_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      token: action.payload.token,
      user: action.payload.user,
      userLocation: action.payload.location,
      jobLocation: action.payload.location,
      themePreference: action.payload.user.themePreference,
      showAlert: true,
      alertType: 'success',
      alertText: action.payload.alertText,
    };
  }

  if (action.type === SETUP_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  //Independent Pricing
  if (action.type === TOGGLE_INDEPENDENT_PRICING_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === TOGGLE_INDEPENDENT_PRICING_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      selectedProductIndependentPricing: action.payload.independentPricing,
    };
  }

  if (action.type === TOGGLE_INDEPENDENT_PRICING_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  //Theme
  if (action.type === TOGGLE_THEME_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === TOGGLE_THEME_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      themePreference: action.payload.themePreference,
    };
  }

  if (action.type === TOGGLE_THEME_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  //Alerts
  if (action.type === GET_ALERTS_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === GET_ALERTS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      alerts: action.payload.alerts,
    };
  }

  if (action.type === GET_ALERTS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  //Download products
  if (action.type === DOWNLOAD_PRODUCTS_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === DOWNLOAD_PRODUCTS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (action.type === DOWNLOAD_PRODUCTS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === ARCHIVE_ALERT_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === ARCHIVE_ALERT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      alerts: action.payload.alerts,
    };
  }

  if (action.type === ARCHIVE_ALERT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === TOGGLE_SIDEBAR) {
    return {
      ...state,
      showSidebar: !state.showSidebar,
    };
  }

  if (action.type === TOGGLE_MACHINE_PRODUCT) {
    return {
      ...state,
      showMachineProduct: !state.showMachineProduct,
    };
  }

  if (action.type === TOGGLE_MACHINE_NOTE) {
    if (state.showMachineNote) {
      return {
        ...state,
        showMachineAddNote: false,
        showMachineNote: !state.showMachineNote,
      };
    }
    return {
      ...state,
      showMachineNote: !state.showMachineNote,
    };
  }

  if (action.type === TOGGLE_MACHINE_ADD_NOTE) {
    return {
      ...state,
      showMachineAddNote: !state.showMachineAddNote,
    };
  }

  if (action.type === TOGGLE_USER_ACTIONS) {
    return {
      ...state,
      selectedUser: action.payload.selectedUser,
      showActions: !state.showActions,
    };
  }

  if (action.type === TOGGLE_FILE_UPLOAD) {
    return {
      ...state,
      showFileUpload: !state.showFileUpload,
    };
  }

  if (action.type === TOGGLE_MONTHY_TRANSACTIONS) {
    return {
      ...state,
      showMonthlyTransactions: !state.showMonthlyTransactions,
    };
  }

  if (action.type === TOGGLE_USER_STATUS_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === TOGGLE_USER_STATUS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      selectedUserStatus: action.payload.newStatus,
      showAlert: true,
      alertType: 'success',
      alertText: 'User Profile Updated!',
    };
  }

  if (action.type === TOGGLE_USER_STATUS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === TOGGLE_STOCKER_OTHER_AMOUNT) {
    return {
      ...state,
      stockerShowOtherAmount: !state.stockerShowOtherAmount,
    };
  }

  if (action.type === TOGGLE_STOCKER_REPLACE) {
    return {
      ...state,
      stockerShowReplace: !state.stockerShowReplace,
      search: '',
    };
  }

  if (action.type === TOGGLE_DRIVER_OTHER_AMOUNT) {
    return {
      ...state,
      driverShowOtherAmount: !state.driverShowOtherAmount,
    };
  }

  if (action.type === TOGGLE_DRIVER_REPLACE) {
    return {
      ...state,
      driverShowReplace: !state.driverShowReplace,
    };
  }

  if (action.type === TOGGLE_SHOW_MESSAGE) {
    return {
      ...state,
      showMessage: !state.showMessage,
    };
  }

  if (action.type === TOGGLE_ERROR_REPORT) {
    return {
      ...state,
      showErrorReport: !state.showErrorReport,
    };
  }

  if (action.type === TOGGLE_TRANSACTION_REPORT) {
    return {
      ...state,
      showTransactionReport: !state.showTransactionReport,
    };
  }

  if (action.type === LOGOUT_USER) {
    return {
      ...initialState,
      user: null,
      token: null,
      userLocation: '',
    };
  }

  if (action.type === UPDATE_USER_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === UPDATE_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      token: action.payload.token,
      user: action.payload.user,
      userLocation: action.payload.location,
      jobLocation: action.payload.location,
      showAlert: true,
      alertType: 'success',
      alertText: 'User Profile Updated!',
    };
  }

  if (action.type === UPDATE_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === DRIVER_REPLACE_ITEM_FLAG_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === DRIVER_REPLACE_ITEM_FLAG_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      selectedReplaceFlag: action.payload.replaceFlag,
      selectedReplaceMessage: action.payload.replaceMessage,
      selectedReplaceDate: action.payload.replaceDate,
      selectedReplaceUser: action.payload.replaceUser,
      showAlert: true,
      alertType: 'success',
      alertText: 'Sent Replace Issue Flag.',
    };
  }

  if (action.type === DRIVER_REPLACE_ITEM_FLAG_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === VERIFY_USER_ROLE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === VERIFY_USER_ROLE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      verifiedUserRole: action.payload.role,
    };
  }

  if (action.type === VERIFY_USER_ROLE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  //ALLOWED MACHINES
  if (action.type === GET_ALLOWED_MACHINES_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === GET_ALLOWED_MACHINES_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      allowedMachines: action.payload.allowedMachines,
    };
  }

  if (action.type === GET_ALLOWED_MACHINES_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPDATE_ALLOWED_MACHINES_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === UPDATE_ALLOWED_MACHINES_SUCCESS) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (action.type === UPDATE_ALLOWED_MACHINES_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  //Active Machines
  if (action.type === GET_ACTIVE_MACHINES_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === GET_ACTIVE_MACHINES_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      activeMachines: action.payload.activeMachines,
    };
  }

  if (action.type === GET_ACTIVE_MACHINES_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === ADD_EXTENDED_REGISTRATION_DATE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === ADD_EXTENDED_REGISTRATION_DATE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Extended Registration Date!',
    };
  }

  if (action.type === ADD_EXTENDED_REGISTRATION_DATE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === RUN_STOCK_CHECK_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === RUN_STOCK_CHECK_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Stock Check ran successfully!',
    };
  }

  if (action.type === RUN_STOCK_CHECK_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === STOCKER_REPLACE_PRODUCT_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === STOCKER_REPLACE_PRODUCT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Product Replaced',
      stockerShowReplace: false,
    };
  }

  if (action.type === STOCKER_REPLACE_PRODUCT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === DRIVER_REPLACE_PRODUCT_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === DRIVER_REPLACE_PRODUCT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Product Replaced',
      driverShowReplace: false,
    };
  }

  if (action.type === DRIVER_REPLACE_PRODUCT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === HANDLE_CHANGE) {
    return {
      ...state,
      page: 1,
      [action.payload.name]: action.payload.value,
    };
  }

  if (action.type === STOCKER_SEARCH_PRODUCTS) {
    return {
      ...state,
      page: 1,
      stockerProductNameSearch: action.payload.productName,
      stockerProductTypeSearch: action.payload.productType,
      stockerProductSortSearch: action.payload.productSort,
      stockerProductCategorySearch: action.payload.categoryType,
    };
  }

  if (action.type === CLEAR_VALUES) {
    const initialState = {
      isEditing: false,
      //Job
      editJobId: '',
      position: '',
      company: '',
      jobLocation: state.userLocation,
      jobType: 'full-time',
      status: 'pending',
      //Product
      editProductId: '',
      productName: '',
      productType: 'single',
      vending: 0,
      caseCost: 0,
      caseQty: 0,
      supplier: "Sam's Club",
      //Machine
      addingMachineId: '',
      editMachineId: '',
      machineName: '',
      deviceId: '',
      machineType: 'snack',
      region: 'Bonn - Blue',
      country: 'USA',
      usState: 'Utah',
      city: '',
      zip: '',
      address: '',
      note: '',
      rows: 0,
      columns: 0,
      //User
      name: '',
      lastName: '',
      role: 'stocker',
      email: '',
      //note
      newNote: '',
      noteType: 'all',
      severity: 'low',
      //Message
      recieverName: '',
      messageType: '',
      message: '',
    };
    return {
      ...state,
      ...initialState,
    };
  }

  if (action.type === CREATE_JOB_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === CREATE_PRODUCT_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === CREATE_MACHINE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === CREATE_USER_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === CREATE_NOTE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === CREATE_MESSAGE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === CREATE_ADMIN_OVERRIDE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === CREATE_JOB_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'New Job Created!',
    };
  }

  if (action.type === CREATE_PRODUCT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'New Product Created!',
    };
  }

  if (action.type === CREATE_MACHINE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'New Machine Created!',
    };
  }

  if (action.type === CREATE_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'New User Created!',
    };
  }

  if (action.type === CREATE_NOTE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Note Created!',
      showMachineAddNote: false,
    };
  }

  if (action.type === CREATE_MESSAGE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Message Created!',
    };
  }

  if (action.type === CREATE_ADMIN_OVERRIDE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Admin Override Password Created!',
    };
  }

  if (action.type === CREATE_JOB_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === CREATE_PRODUCT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === CREATE_MACHINE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === CREATE_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === CREATE_NOTE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === CREATE_MESSAGE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === CREATE_ADMIN_OVERRIDE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_DAILY_TRANSACTIONS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_STOCKED_DELIVERED_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_ERROR_REPORT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_TRANSACTION_REPORT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_MONTHLY_TRANSACTIONS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_MONTHLY_TRANSACTIONS_REPORT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === GET_JOBS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === GET_PRODUCTS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === GET_STOCKER_PRODUCTS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === GET_MACHINES_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === GET_USERS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === GET_NOTES_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === GET_ADMIN_OVERRIDE_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === GET_MESSAGES_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === GET_DAILY_TRANSACTIONS_BEGIN) {
    return {
      ...state,
      //isLoading: true,
      //showAlert: false
    };
  }

  if (action.type === GET_STOCKED_DELIVERED_BEGIN) {
    return {
      ...state,
      //isLoading: true,
      //showAlert: false
    };
  }

  if (action.type === GET_ERROR_REPORT_BEGIN) {
    return {
      ...state,
      //isLoading: true,
      //showAlert: false
    };
  }

  if (action.type === GET_TRANSACTION_REPORT_BEGIN) {
    return {
      ...state,
      //isLoading: true,
      //showAlert: false
    };
  }

  if (action.type === GET_MONTHLY_TRANSACTIONS_BEGIN) {
    return {
      ...state,
      //isLoading: true,
      //showAlert: false
    };
  }

  if (action.type === GET_MONTHLY_TRANSACTIONS_REPORT_BEGIN) {
    return {
      ...state,
      //isLoading: true,
      //showAlert: false
    };
  }

  if (action.type === GET_MACHINES_BEGIN) {
    return {
      ...state,
      //isLoading: true,
      //showAlert: false
    };
  }

  if (action.type === MARK_MESSAGE_AS_READ_BEGIN) {
    return {
      ...state,
      //isLoading: true,
      //showAlert: false
    };
  }

  if (action.type === GET_ACTIONS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === SET_MACHINE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === SET_USER_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === GET_JOBS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      jobs: action.payload.jobs,
      totalJobs: action.payload.totalJobs,
      numOfPages: action.payload.numOfPages,
    };
  }

  if (action.type === GET_PRODUCTS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      products: action.payload.products,
      totalProducts: action.payload.totalProducts,
      numOfPages: action.payload.numOfPages,
    };
  }

  if (action.type === GET_STOCKER_PRODUCTS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      stockerProducts: action.payload.stockerProducts,
      totalStockerProducts: action.payload.totalStockerProducts,
      numOfStockerPages: action.payload.numOfStockerPages,
    };
  }

  if (action.type === GET_MACHINES_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      machines: action.payload.machines,
      totalMachines: action.payload.totalMachines,
      numOfPages: action.payload.numOfPages,

      //Clear stocker
      stockerShowReplace: false,

      //Clear driver
      driverShowReplace: false,
    };
  }

  if (action.type === STOCKER_SELECT_REPLACE_PRODUCT) {
    return {
      ...state,
      stockerReplacedProductId: action.payload.productId,
      stockerReplacedProductName: action.payload.productName,
      stockerReplacedProductType: action.payload.productType,
      stockerReplacedProductPrice: action.payload.productPrice,
    };
  }

  if (action.type === MARK_MESSAGE_AS_READ_SUCCESS) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (action.type === GET_USERS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      users: action.payload.users,
      totalUsers: action.payload.totalUsers,
      numOfPages: action.payload.numOfPages,
    };
  }

  if (action.type === GET_NOTES_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      notes: action.payload.notes,
      totalNotes: action.payload.totalNotes,
    };
  }

  if (action.type === GET_ADMIN_OVERRIDE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      adminOverridePassword: action.payload.password,
    };
  }

  if (action.type === GET_MESSAGES_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      messages: action.payload.messages,
      totalMessages: action.payload.totalMessages,
      totalUnredMessages: action.payload.totalUnredMessages,
    };
  }

  if (action.type === GET_DAILY_TRANSACTIONS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      dailyReports: action.payload.reports,
      dailyProcessedDate: action.payload.processedDate,
      dailyTransactions: action.payload.transactions,
      dailyTotal: action.payload.total,
    };
  }

  if (action.type === GET_STOCKED_DELIVERED_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      stocked: action.payload.stocked,
      delivered: action.payload.delivered,
      needsStock: action.payload.needsStock,
    };
  }

  if (action.type === GET_ERROR_REPORT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      emptyColumnSpin: action.payload.columnSpin,
      machineNotFound: action.payload.notFound,
      errors: action.payload.errArr,
    };
  }

  if (action.type === GET_TRANSACTION_REPORT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      transactionCount: action.payload.transactionCount,
      transactions: action.payload.transactionsArr,
    };
  }

  if (action.type === GET_MONTHLY_TRANSACTIONS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      monthlyTransactions: action.payload.transactions,
    };
  }

  if (action.type === GET_MONTHLY_TRANSACTIONS_REPORT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (action.type === GET_ACTIONS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      actions: action.payload.actions,
      totalActions: action.payload.totalActions,
    };
  }

  if (action.type === SET_MACHINE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      selectedId: action.payload._id,
      selectedMachineName: action.payload.machineName,
      selectedDeviceId: action.payload.deviceId,
      selectedMachineType: action.payload.machineType,
      selectedStatus: action.payload.status,
      selectedActivity: action.payload.activity,
      selectedRegion: action.payload.region,
      selectedCountry: action.payload.country,
      selectedUsState: action.payload.usState,
      selectedCity: action.payload.city,
      selectedZip: action.payload.zip,
      selectedAddress: action.payload.address,
      selectedNote: action.payload.note,
      selectedRows: action.payload.row,
      selectedColumns: action.payload.columns,
      selectedServicedOn: action.payload.lastVisitDate,
      selectedServicedBy: action.payload.servicedByName,

      //clear machines
      notes: [],
      totalNotes: 0,
      severity: 'low',
      noteType: 'all',
      showMachineNote: false,
      showMachineAddNote: false,

      //Clear stocker replace
      stockerShowReplace: false,

      //Clear driver replace
      driverShowReplace: false,
    };
  }

  if (action.type === SET_MACHINE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === MARK_MESSAGE_AS_READ_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === SET_USER_SUCCESS) {
    return {
      ...state,
      selectedUserId: action.payload.id,
      selectedUserName: action.payload.userName,
      selectedUserEmail: action.payload.email,
      selectedLastName: action.payload.lastName,
      selectedRole: action.payload.role,
      selectedUserStatus: action.payload.status,
      selectedCreatedAt: action.payload.createdAt,
    };
  }

  if (action.type === SET_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === SET_EDIT_JOB) {
    const job = state.jobs.find((job) => job._id === action.payload.id);
    const { _id, position, company, jobLocation, jobType, status } = job;
    return {
      ...state,
      isEditing: true,
      editJobId: _id,
      position,
      company,
      jobLocation,
      jobType,
      status,
    };
  }

  if (action.type === SET_EDIT_PRODUCT) {
    const product = state.products.find(
      (product) => product._id === action.payload.id,
    );

    const {
      _id,
      productName,
      productType,
      vending,
      caseCost,
      caseQty,
      supplier,
    } = product;
    return {
      ...state,
      isEditing: true,
      editProductId: _id,
      productName,
      productType,
      vending,
      caseCost,
      caseQty,
      supplier,
    };
  }

  if (action.type === SET_EDIT_MACHINE) {
    const machine = state.machines.find(
      (machine) => machine._id === action.payload.id,
    );
    const {
      _id,
      machineName,
      deviceId,
      machineType,
      country,
      usState,
      city,
      zip,
      address,
      note,
      rows,
      columns,
    } = machine;
    return {
      ...state,
      isEditing: true,
      editMachineId: _id,
      machineName,
      deviceId,
      machineType,
      country,
      usState,
      city,
      zip,
      address,
      note,
      rows,
      columns,
    };
  }

  if (action.type === SET_EDIT_USER) {
    const user = state.users.find((user) => user._id === action.payload.id);
    const { _id, name, lastName, email, role } = user;
    return {
      ...state,
      isEditing: true,
      editUserId: _id,
      name,
      lastName,
      email,
      role,
    };
  }

  if (action.type === EDIT_MACHINE_PRODUCT_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === EDIT_CURRENT_STOCK_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === EDIT_MACHINE_PRODUCT_LOCATION_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === UPDATE_ADMIN_OVERRIDE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === EDIT_MACHINE_PRODUCT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Product Updated!',
    };
  }

  if (action.type === EDIT_CURRENT_STOCK_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Current Stock Updated!',
    };
  }

  if (action.type === EDIT_MACHINE_PRODUCT_LOCATION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Product Mapped!',
    };
  }

  if (action.type === UPDATE_ADMIN_OVERRIDE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Admin Override Updated!',
    };
  }

  if (action.type === EDIT_MACHINE_PRODUCT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === EDIT_CURRENT_STOCK_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === EDIT_MACHINE_PRODUCT_LOCATION_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPDATE_ADMIN_OVERRIDE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === SET_ADD_MACHINE_PRODUCT) {
    return {
      ...state,
      isAdding: true,
      addingMachineId: action.payload.id,
    };
  }

  if (action.type === SET_MACHINE_STATUS) {
    return {
      ...state,
      machineStatus: action.payload.machineStatus,
      isStocker: action.payload.isStocker,
      isDriver: action.payload.isDriver,
    };
  }

  if (action.type === UPDATE_GRID_VIEW) {
    return {
      ...state,
      gridView: !state.gridView,
    };
  }

  if (action.type === UPDATE_PRODUCT_INFO_VIEW) {
    return {
      ...state,
      selectedProductId: action.payload.id,
      selectedProductName: action.payload.productName,
      selectedProductType: action.payload.productType,
      selectedLocation: action.payload.location,
      selectedProductPrice: action.payload.price,
      selectedProductSales: action.payload.sales,
      selectedProductMaxStock: action.payload.maxStock,
      selectedProductCurrentStock: action.payload.currentStock,
      selectedProductStocked: action.payload.stocked,
      selectedProductStockedDate: action.payload.stockedDate,
      selectedProductStockerReplaced: action.payload.stockerReplaced,
      selectedReplaceFlag: action.payload.replaceFlag,
      selectedProductIndependentPricing: action.payload.independentPricing,

      //Replace Product
      stockerReplacedProductName: action.payload.stockerReplacedProductName,
      stockerReplacedProductType: action.payload.stockerReplacedProductType,
      stockerReplacedProductPrice: action.payload.stockerReplacedProductPrice,
      stockerReplacedProductMaxStock:
        action.payload.stockerReplacedProductMaxStock,
    };
  }

  if (action.type === SHOW_HIDE_PRODUCT_INFO_VIEW) {
    return {
      ...state,
      productInfoView: !state.productInfoView,
    };
  }

  if (action.type === CLEAR_SELECTED_PRODUCT) {
    return {
      ...state,
      selectedProductId: '',
      selectedProductName: '',
      selectedProductType: '',
      selectedLocation: '',
      selectedProductPrice: '',
      selectedProductSales: '',
      selectedProductMaxStock: '',
      selectedProductCurrentStock: '',
      selectedProductStocked: '',
      selectedProductStockedDate: '',
      selectedProductStockerReplaced: false,
      selectedReplaceFlag: false,
      selectedProductIndependentPricing: false,

      //Replace Product
      stockerReplacedProductName: '',
      stockerReplacedProductType: '',
      stockerReplacedProductPrice: 0,
      stockerReplacedProductMaxStock: 0,
    };
  }

  if (action.type === ADD_MACHINE_PRODUCT_BEGIN) {
    return {
      ...state,
      isAdding: true,
    };
  }

  if (action.type === ADD_MACHINE_PRODUCT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Added product',
    };
  }

  if (action.type === ADD_MACHINE_PRODUCT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === DELETE_JOB_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === DELETE_PRODUCT_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === DELETE_MACHINE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === DELETE_USER_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === DELETE_NOTE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === ARCHIVE_NOTE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === DELETE_MACHINE_PRODUCT) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === EDIT_MACHINE_ACTIVITY) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === UPDATE_MACHINE_STATUS) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === EDIT_JOB_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === EDIT_JOB_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Job Updated!',
    };
  }

  if (action.type === EDIT_JOB_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === EDIT_PRODUCT_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === EDIT_PRODUCT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Product Updated!',
    };
  }

  if (action.type === EDIT_PRODUCT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === EDIT_MACHINE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === EDIT_MACHINE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Machine Updated!',
    };
  }

  if (action.type === EDIT_MACHINE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === EDIT_USER_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === EDIT_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'User Updated!',
    };
  }

  if (action.type === EDIT_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === SHOW_STATS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === SHOW_STATS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      stats: action.payload.stats,
      monthlyApplications: action.payload.monthlyApplications,
    };
  }

  if (action.type === CLEAR_FILTERS) {
    return {
      ...state,
      search: '',
      searchStatus: 'all',
      searchType: 'all',
      sort: 'latest',

      //Machine specific
      activeInactive: 'all',
      machineStatus: 'all',
    };
  }

  if (action.type === CHANGE_PAGE) {
    return {
      ...state,
      page: action.payload.page,
    };
  }

  if (action.type === REMOVE_SELECTED_MACHINE) {
    return {
      ...state,
      isAdding: false,
      addingMachineId: '',
      selectedId: '',
      selectedMachineName: '',
      selectedDeviceId: '',
      selectedMachineType: '',
      selectedStatus: '',
      selectedActivity: '',
      selectedRegion: '',
      selectedCountry: '',
      selectedUsState: '',
      selectedCity: '',
      selectedZip: '',
      selectedAddress: '',
      selectedNote: '',
      selectedCreatedBy: '',
      selectedCreatedAt: '',
      selectedRows: [],
      selectedColumns: 0,
      selectedTopSeller: '',
      selectedServicedOn: '',
      selectedServicedBy: '',
    };
  }

  if (action.type === FILL_SELECTED_MACHINE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === FILL_SELECTED_MACHINE_SUCCESS) {
    return {
      ...state,
      stockerOtherAmount: 0,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Product Filled!',
    };
  }

  if (action.type === FILL_SELECTED_MACHINE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === DRIVER_FILL_SELECTED_MACHINE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === DRIVER_FILL_SELECTED_MACHINE_SUCCESS) {
    return {
      ...state,
      driverOtherAmount: 0,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Product Filled!',
    };
  }

  if (action.type === DRIVER_FILL_SELECTED_MACHINE_ABORT) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (action.type === DRIVER_FILL_SELECTED_MACHINE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === FILE_UPLOAD_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === FILE_UPLOAD_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'File Uploaded!',
    };
  }

  if (action.type === FILE_UPLOAD_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === PROCESS_TRANSACTIONS_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === REMOVE_TRANSACTION_FILE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === PROCESS_TRANSACTIONS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'File Proccesed!',
    };
  }

  if (action.type === REMOVE_TRANSACTION_FILE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'File Uploader is empty!',
    };
  }

  if (action.type === PROCESS_TRANSACTIONS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === REMOVE_TRANSACTION_FILE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  if (action.type === UPDATE_PRODUCT_IDS_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === UPDATE_PRODUCT_IDS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'success',
      alertText: 'Product Ids updated',
    };
  }

  if (action.type === UPDATE_PRODUCT_IDS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }

  throw new Error(`no such acion :${action.type}`);
};

export default reducer;
