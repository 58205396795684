import styled from 'styled-components'

const Wrapper = styled.article`
background: var(--white);
border-radius: var(--borderRadius);
display: grid;
grid-template-rows: 1fr;
box-shadow: var(--shadow-2);
width: 1fr;

  header {
    
    padding: 1rem 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    h5 {
      letter-spacing: 0;
    }
  }
  .main-icon {
    width: 100%;
    height: 80px;
    display: grid;
    place-items: center;
    background: var(--primary-500);
    border-radius: var(--borderRadius);
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--white);
    margin-right: 2rem;
  }

  .needs-fill {
    background: #ff0000;
  }

  .filled {
    background: #ffa500;
  }

  .info {
    h5 {
        
        padding-top: 10px;
        text-align: center;
    }
  }

  &:hover {
    border: 2px solid red;
  }
`

export default Wrapper

