import React from 'react';
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/StockerProduct';
import moment from 'moment';

const  StockerProduct = ({ 
    _id, 
    productName,
    productType,
    vending,
    createdAt
}) => {

    const { stockerSelectReplaceProduct } = useAppContext();  
    let date = moment(createdAt);
    date = date.format('MMM Do, YYYY ')
    return (
        <Wrapper>
                <div className='content'>
                    <h5>{productName}</h5>
                    <p>{productType} - Price: ${Number(vending).toFixed(2)}</p>
                    <button type='button' className='btn select-btn' onClick={() => stockerSelectReplaceProduct(_id, productName, productType, vending)} >
                            Select
                        </button>
                </div>
        </Wrapper>
    )
}

export default StockerProduct;
