import styled from 'styled-components';

const Wrapper = styled.article`
  background: var(--white);
  border-radius: var(--borderRadius);
  display: grid;
  grid-template-rows: 1fr auto;
  box-shadow: var(--shadow-2);
  width: 1fr;

  header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid var(--grey-100);
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    h5 {
      letter-spacing: 0;
    }
  }
  .main-icon {
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    background: var(--primary-500);
    border-radius: var(--borderRadius);
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--white);
    margin-right: 2rem;
  }

  .replace-warning svg {
    font-size: 2em;
    color: red;
  }

  .info {
    h5 {
      margin-bottom: 0.25rem;
    }
    p {
      margin: 0;
      text-transform: capitalize;
      color: var(--grey-400);
      letter-spacing: var(--letterSpacing);
    }
  }

  .declined {
    color: #d66a6a;
    background: #ffeeee;
  }

  .content {
    padding: 1rem 1.5rem;
  }

  .content-center {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 0.25rem;
  }

  .needs-fill {
    background-color: #ffb3b3;
  }

  .info-advisory {
    h5 {
      margin-bottom: 0.25rem;
      color: #ff0000;
    }
    p {
      margin: 0;
      text-transform: capitalize;
      color: var(--grey-400);
      letter-spacing: var(--letterSpacing);
    }
  }

  .status {
    border-radius: var(--borderRadius);
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
    text-align: center;
    width: 100px;
    height: 30px;
  }

  footer {
    margin-top: 1rem;
  }

  .edit-btn,
  .delete-btn,
  .activate-btn,
  .inactivate-btn,
  .view-btn {
    letter-spacing: var(--letterSpacing);
    cursor: pointer;
    height: 30px;
  }
  .edit-btn {
    color: var(--blue-dark);
    background: var(--blue-light);
    margin-right: 0.5rem;
  }

  .delete-btn {
    color: var(--red-dark);
    background: var(--red-light);
    margin-right: 0.5rem;
  }

  .activate-btn {
    color: var(--blue-dark);
    background: var(--blue-light);
    margin-right: 0.5rem;
  }

  .inactivate-btn {
    color: var(--red-dark);
    background: var(--red-light);
    margin-right: 0.5rem;
  }

  .view-btn {
    color: var(--blue-dark);
    background: var(--blue-light);
    margin-right: 0.5rem;
  }

  &:hover .actions {
    visibility: visible;
  }

  .no-product {
    padding-top: 1rem;
    width: 100%;
    text-align: center;
  }

  .independent-pricing {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  @media only screen and (max-width: 768px) {
    .btn {
      margin-top: 10px;
    }
  }
`;

export default Wrapper;
