import React, { useEffect } from 'react';
import { useAppContext } from '../../context/appContext';
import { ProfileInfo, ProfileAdmin, ProfileSuperAdmin } from '../../components';

const Profile = () => {

    const { 
        user,
        getAdminOverride,
        verifiedUserRole,
        verifyUserRole
      } = useAppContext();

      useEffect(() => {
        getAdminOverride();

        const fetchData = async () => {
          try {
            verifyUserRole(user._id);
            //setUserRole(verifiedUserRole);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

      let admin = false;
      let superAdmin = false;
      if (verifiedUserRole.role === 'admin') {
        admin = true;
      } else if (verifiedUserRole.role === 'super-admin') {
        admin = true;
        superAdmin = true;
      }

    return (
        <>
            <ProfileInfo />
            <br></br>
            {admin ? <ProfileAdmin /> : <></> }
            <br></br>
            {superAdmin ? <ProfileSuperAdmin /> : <></>}
        </>
    )

   
};

export default Profile;