import React, { useEffect } from 'react';
import { FormRow, Alert } from '.';
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/DashboardFormPage';

const ProfileSuperAdmin = () => {

    const {  
        showAlert, 
        isLoading, 
        handleChange,
        verifiedUserRole,
        verifyUserRole,
        getAllowedMachines,
        allowedMachines,
        updateAllowedMachines,
    } = useAppContext();

    useEffect(() => {
        getAllowedMachines();

        const fetchData = async () => {
          try {
            verifyUserRole();
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        

        updateAllowedMachines(allowedMachines);
    }

    const handleAdminInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        handleChange({ name, value });
    }

      let superAdmin = false;
       if (verifiedUserRole.role === 'super-admin') {
        superAdmin = true;
      }

    return (
        <Wrapper>
            { superAdmin ? 
            <>
            <form className='form' onSubmit={handleSubmit}>
                <h3>Super Admin</h3>
                {showAlert && <Alert />}
                <div className='form-center'>
                    <FormRow 
                        labelText='allowed machines'
                        type='text' 
                        name='allowedMachines' 
                        value={allowedMachines} 
                        handleChange={handleAdminInput} 
                    />
                    <button className='btn btn-block' type='submit'disabled={isLoading}>
                        {isLoading ? 'Please Wait...' : 'Update'}
                    </button>
                </div>
            </form>
            </>
            : <></> }
        </Wrapper>
    )
};

export default ProfileSuperAdmin;