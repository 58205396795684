import React from 'react'
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/Transactions'

const Transactions = () => {

    const {
        processTransactions,
        removeTransactionFile
    } = useAppContext();

  return (
    <Wrapper>
    <button type='button' className='btn edit-btn' onClick={() => processTransactions()} >
        Process Transactions
    </button>
    <button type='button' className='btn edit-btn' onClick={() => removeTransactionFile()} >
        Remove File
    </button>
    </Wrapper>
  )
}

export default Transactions