import React from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/Job';
import moment from 'moment';

import PercentageBar from './PercentageBar';

const Machine = ({
  _id,
  machineName,
  machineType,
  status,
  stockReason,
  activity,
  region,
  row,
  createdAt,
  servicedByName,
  lastVisitDate,
}) => {
  const {
    setEditMachine,
    deleteMachine,
    editActivity,
    setMachine,
    isStocker,
    isDriver,
  } = useAppContext();

  let date = moment(createdAt);
  date = date.format('MMM Do, YYYY ');

  const formatDateTime = (dateTimeString) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      'en-US',
      options,
    );

    return formattedDate;
  };

  let zeroes;
  let percentage;
  let percentageNoText;
  let visit;

  const processStockReason = (str) => {
    if (str.includes(',')) {
      const parts = str.split(',');

      parts.map((part) => {
        if (part.includes('Zeroes')) {
          const number = parseFloat(part.match(/\d+/));
          zeroes = `${number} EMPTY SLOTS\n`;
        } else if (part.includes('Percentage')) {
          const number = parseFloat(part.match(/[\d.]+/));
          const percent = (number * 100).toFixed(2);
          percentage = `${percent}% EMPTY\n`;
          percentageNoText = percent;
        } else if (part.includes('Visit')) {
          const number = parseFloat(part.match(/\d+/));
          visit = `${number} DAYS SINCE LAST VISIT\n`;
        }
      });
    } else {
      //single reason
      if (str.includes('Zeroes')) {
        const number = parseFloat(str.match(/\d+/));
        zeroes = `${number} EMPTY SLOTS\n`;
      } else if (str.includes('Percentage')) {
        const number = parseFloat(str.match(/[\d.]+/));
        const percent = (number * 100).toFixed(2);
        percentage = `${percent}% EMPTY\n`;
        percentageNoText = percent;
      } else if (str.includes('Visit')) {
        const number = parseFloat(str.match(/\d+/));
        visit = `${number} DAYS SINCE LAST VISIT\n`;
      }
    }
  };

  processStockReason(stockReason);

  let percentInMachine;

  const processPercent = (rows) => {
    let max = 0;
    let current = 0;

    for (let i = 0; i < rows.length; i++) {
      max += rows[i].maxStock;
      current += rows[i].currentStock;
    }

    if (max > 0) {
      percentInMachine = ((current / max) * 100).toFixed(2);
    } else {
      percentInMachine = 0;
    }
  };

  processPercent(row);

  return (
    <Wrapper>
      <header>
        <div className={activity ? 'main-icon' : 'main-icon-red'}>
          {machineName.charAt(0)}
        </div>
        <div className='info'>
          <h5>{machineName}</h5>
          <p>
            {machineType}
            {isStocker ? ' - ' + status.toUpperCase() : null}{' '}
          </p>
          {/* <p>{!activity ? 'Machine Inactive' : 'Machine Active'}</p> */}

          <PercentageBar percentage={percentInMachine} />
        </div>
      </header>
      <div className='content'>
        <div className='content-center'>
          {isDriver ? ( //If driver is true show driver else determine if stocker is true or not
            <>
              <div>Machine Status: {status}</div>
              <div>% Full: {percentInMachine}%</div>
              <div>{zeroes ? '- ' + zeroes : null}</div>
            </>
          ) : (
            <>
              {isStocker ? (
                <>
                  {/* <div>{status.toUpperCase()}</div> */}
                  <ul>
                    {zeroes || visit || percentage ? (
                      <div>
                        <li>Stock Reason(s)</li>
                        <hr />
                      </div>
                    ) : null}
                    <li>{zeroes ? '- ' + zeroes : null}</li>
                    <li>{percentage ? '- ' + percentage : null}</li>
                    <li>{visit ? '- ' + visit : null}</li>
                  </ul>
                </>
              ) : (
                <>
                  <div>Region: {region}</div>
                  {/* <div>Address: {address} {city}, {zip}</div>
                            <div>Note: {note}</div> */}
                  <div>Serviced On: {formatDateTime(lastVisitDate)}</div>
                  <div>% Full: {percentInMachine}%</div>
                  <div>Serviced By: {servicedByName}</div>
                  {/* <div>Top Seller: </div> */}
                </>
              )}
            </>
          )}
        </div>
        <footer>
          <div className='action'>
            {isDriver ? ( //If driver is true show driver else determine if stocker is true or not
              <>
                <Link
                  to='/machine-driver'
                  className='btn view-btn'
                  onClick={() => setMachine(_id)}
                >
                  Deliver
                </Link>
              </>
            ) : (
              <>
                {isStocker ? (
                  <>
                    <Link
                      to='/machine-stocker'
                      className='btn view-btn'
                      onClick={() => setMachine(_id)}
                    >
                      Stock
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      to='/machine-main'
                      className='btn view-btn'
                      onClick={() => setMachine(_id)}
                    >
                      View
                    </Link>
                    <Link
                      to='/add-machine'
                      className='btn edit-btn'
                      onClick={() => setEditMachine(_id)}
                    >
                      Edit
                    </Link>
                    <button
                      type='button'
                      className={
                        !activity ? 'btn activate-btn' : 'btn inactivate-btn'
                      }
                      onClick={() => editActivity(_id, activity)}
                    >
                      {!activity ? 'Activate' : 'Inactivate'}
                    </button>
                    <button
                      type='button'
                      className='btn delete-btn'
                      onClick={() => deleteMachine(_id)}
                    >
                      Delete
                    </button>
                  </>
                )}{' '}
              </>
            )}
          </div>
        </footer>
      </div>
    </Wrapper>
  );
};

export default Machine;
