import React from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/Job';

const SelectedMachineInfo = ({ machine, isStocker, isDriver }) => {
  const {
    selectedId,
    selectedMachineName,
    selectedActivity,
    selectedRegion,
    selectedCity,
    selectedZip,
    selectedAddress,
    selectedNote,
    selectedRows,
    selectedServicedOn,
    selectedServicedBy,
  } = machine;

  const {
    setMachineProduct,
    toggleMachineProduct,
    showMachineProduct,
    toggleMachineNote,
    showMachineNote,
    toggleMachineAddNote,
    showMachineAddNote,
    updateMachineStatus,
    gridView,
    updateGridView,
    totalNotes,
    updateProductIds,
  } = useAppContext();

  const formatDateTime = (dateTimeString) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      'en-US',
      options,
    );

    return formattedDate;
  };

  let percentInMachine;

  const processPercent = (rows) => {
    let max = 0;
    let current = 0;

    for (let i = 0; i < rows.length; i++) {
      max += rows[i].maxStock;
      current += rows[i].currentStock;
    }
    if (max > 0) {
      percentInMachine = ((current / max) * 100).toFixed(2);
    } else {
      percentInMachine = 0;
    }
  };

  processPercent(selectedRows);

  return (
    <Wrapper>
      <header>
        <div className='main-icon'>{selectedMachineName.charAt(0)}</div>
        <div className='info'>
          <h5>{selectedMachineName}</h5>
          <p>{!selectedActivity ? 'Machine Inactive' : selectedActivity}</p>
        </div>
      </header>
      <div className='content'>
        <div className='content-center'>
          <div>Region: {selectedRegion}</div>
          <div>
            Serviced On:{' '}
            {selectedServicedOn ? formatDateTime(selectedServicedOn) : 'N/A'}
          </div>

          <div>
            Address: {selectedAddress} {selectedCity}, {selectedZip}
          </div>
          <div>
            Serviced By: {selectedServicedBy ? selectedServicedBy : 'N/A'}
          </div>

          <div>Note: {selectedNote}</div>
          <div>% Full: {percentInMachine}</div>
          {/* <div>Top Seller: {selectedTopSeller ? selectedTopSeller : 'No Top Seller'}</div> */}
        </div>
        <footer>
          <hr />
          <div className='action'>
            {isDriver ? ( //If driver is true show driver else determine if stocker is true
              <>
                <button
                  type='button'
                  className={'btn view-btn'}
                  onClick={() => updateMachineStatus(selectedId, 'delivered')}
                >
                  Set to Machine Filled
                </button>
                <button
                  type='button'
                  className={gridView ? 'btn view-btn' : 'btn edit-btn'}
                  onClick={() => updateGridView()}
                >
                  {gridView ? 'List' : 'Grid'}
                </button>
              </>
            ) : (
              <>
                {isStocker ? (
                  <>
                    <button
                      type='button'
                      className={'btn view-btn'}
                      onClick={() => updateMachineStatus(selectedId, 'stocked')}
                    >
                      Set for Delivery
                    </button>
                    <button
                      type='button'
                      className={gridView ? 'btn view-btn' : 'btn edit-btn'}
                      onClick={() => updateGridView()}
                    >
                      {gridView ? 'List' : 'Grid'}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type='button'
                      className={
                        showMachineProduct ? 'btn hide-btn' : 'btn view-btn'
                      }
                      onClick={() => toggleMachineProduct()}
                    >
                      {showMachineProduct ? 'Hide Products' : 'View Products'}
                    </button>
                    {showMachineProduct && (
                      <Link
                        to='/all-products'
                        className='btn edit-btn'
                        onClick={() => setMachineProduct(selectedId)}
                      >
                        Add Product
                      </Link>
                    )}
                    <button
                      type='button'
                      className={
                        showMachineNote
                          ? 'btn hide-btn notification-button'
                          : 'btn view-btn notification-button'
                      }
                      onClick={() => toggleMachineNote()}
                    >
                      {showMachineNote ? 'Hide Notes' : 'View Notes'}
                      {totalNotes > 0 && (
                        <span className='notification-counter'>
                          {totalNotes}
                        </span>
                      )}
                    </button>
                    {showMachineNote && (
                      <button
                        type='button'
                        className='btn edit-btn'
                        onClick={() => toggleMachineAddNote()}
                      >
                        {showMachineAddNote ? 'Close' : 'Add Note'}
                      </button>
                    )}
                    <button
                      type='button'
                      className={gridView ? 'btn view-btn' : 'btn edit-btn'}
                      onClick={() => updateGridView()}
                    >
                      {gridView ? 'List' : 'Grid'}
                    </button>
                    {/* Removed - no longer needed */}
                    {/* <button type='button' className='btn edit-btn' onClick={() => updateProductIds(selectedId)} >
                                    Update product IDs
                                </button> */}
                  </>
                )}
              </>
            )}
          </div>
        </footer>
      </div>
    </Wrapper>
  );
};

export default SelectedMachineInfo;
