import React, { useState } from 'react';
import { FormRow, FormRowSelect, Alert } from '../../components';
import { useAppContext } from '../../context/appContext';
import { Navigate } from "react-router-dom";

import Wrapper from '../../assets/wrappers/DashboardFormPage';
import definitions from '../../utils/definitions';

const AddUser = () => {

    const { 
        isLoading,
        isEditing,
        showAlert, 
        displayAlert, 
        handleChange,
        clearValues,
        name,
        email,
        lastName,
        role,
        userTypeOptions,
        createUser,
        editUser,
        user
      } = useAppContext();

      const [definition, setDefinition] = useState(definitions.stocker)

      const handleSubmit = (e) => {
          e.preventDefault();

          if (!name || !lastName || !email || !role) {
              displayAlert();
              return;
          } 

          if (isEditing) {
            editUser()
            return
          }
          createUser();
      }

      const handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        handleChange({ name, value });

        if (name === 'role') {
            setDefinition(definitions[value])
        }
      }

      if (user.role === 'stocker' || user.role === 'driver' || user.role === 'stocker-driver') {
        return <Navigate to="/"/>
    }

    return (
        <Wrapper>
            <form className='form'>
                <h3>{isEditing ? 'edit user' : 'add user'}</h3>
                {showAlert && <Alert />}
                <div className='form-center'>
                    {/* name */}
                    <FormRow 
                        labelText='First Name'
                        type='text' 
                        name='name' 
                        value={name}
                        handleChange={handleUserInput}
                    />
                    {/* lastName */}
                    <FormRow 
                        type='text'
                        name='lastName' 
                        value={lastName}
                        handleChange={handleUserInput}
                    />
                    {/* email */}
                    <FormRow 
                        type='text' 
                        name='email' 
                        value={email}
                        handleChange={handleUserInput}
                    />
                    {/* role */}
                        <FormRowSelect
                        labelText='role*'
                        name='role'
                        value={role}
                        handleChange={handleUserInput}
                        list={userTypeOptions}
                    />
                    {/* btn Container */}
                    <div className='btn-container'>
                        <button 
                            type='submit' 
                            className='btn btn-block sumbit-btn' 
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            submit
                        </button>
                        <button 
                            className='btn btn-block clear-btn' 
                            onClick={(e) => {
                                e.preventDefault();
                                clearValues();
                            }}
                            >
                                clear
                            </button>
                    </div>

                </div>
            </form>

            <p>* {definition}</p>
        </Wrapper>
    )
};

export default AddUser;