import React from 'react';
import main from '../assets/images/main3.svg';
import Wrapper from '../assets/wrappers/LandingPage';
import { Logo } from '../components';
import { Link } from 'react-router-dom'

const Landing = () => {
    return (
        <Wrapper>
            <nav>
                <Logo />
            </nav>
            <div className='container page'>
                {/* INFO */}
                <div className='info'>
                    <h1>
                        Vending <span>managing</span> app
                    </h1>
                    <p>
                        Manage your Vending from anywhere, anytime.
                    </p>
                    <Link to='/register' className='btn btn-hero'>Login/Register</Link>
                </div>
                <div>
                    <img src={main} alt='manage vending' className='img
                    main-img'/>
                </div>
            </div>
        </Wrapper>
    )
}

export default Landing;