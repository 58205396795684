import React from 'react';
import { IoBarChartSharp } from 'react-icons/io5';
import { ImProfile } from 'react-icons/im';
import { GiVendingMachine } from 'react-icons/gi';
import { MdOutlineAddCircleOutline } from 'react-icons/md';
import { AiOutlineUser } from 'react-icons/ai';
import { GiChocolateBar } from 'react-icons/gi';
import { FaDropbox } from 'react-icons/fa';
import { AiFillCar } from 'react-icons/ai';

//ROLES -> all: 0, stocker: 1, driver: 2, stocker-driver: 3, admin: 4, super-admin: 5

const links = [
  {
    id: 1,
    text: 'Dashboard',
    path: '/',
    icon: <IoBarChartSharp />,
    role: 0,
  },
  {
    id: 2,
    text: 'All Machines',
    path: 'all-machines',
    icon: <GiVendingMachine />,
    role: 4,
  },

  {
    id: 3,
    text: 'Add Machine',
    path: 'add-machine',
    icon: <MdOutlineAddCircleOutline />,
    role: 4,
  },
  {
    id: 4,
    text: 'All Products',
    path: 'all-products',
    icon: <GiChocolateBar />,
    role: 4,
  },

  {
    id: 5,
    text: 'Add Product',
    path: 'add-product',
    icon: <MdOutlineAddCircleOutline />,
    role: 4,
  },
  {
    id: 6,
    text: 'All Users',
    path: 'all-users',
    icon: <AiOutlineUser />,
    role: 4,
  },

  {
    id: 7,
    text: 'Add User',
    path: 'add-user',
    icon: <MdOutlineAddCircleOutline />,
    role: 4,
  },
  {
    id: 8,
    text: 'Profile',
    path: 'profile',
    icon: <ImProfile />,
    role: 0,
  },
  {
    id: 9,
    text: 'Picker',
    path: 'stocker',
    icon: <FaDropbox />,
    role: 1,
  },
  {
    id: 10,
    text: 'Driver',
    path: 'driver',
    icon: <AiFillCar />,
    role: 2,
  },
];

export default links;
