import React from 'react';
import { useAppContext } from '../context/appContext';
import Loading from './Loading';
import Wrapper from '../assets/wrappers/SelectedProductInfoContainer';
import moment from 'moment';
import StockerReplaceProduct from './StockerReplaceProduct';
import DriverReplaceProduct from './DriverReplaceProduct';
import { MdOutlineWarning } from 'react-icons/md';

const SelectedProductInfoContainer = () => {
  const {
    selectedProductId,
    selectedProductName,
    selectedProductType,
    selectedLocation,
    selectedProductPrice,
    selectedProductSales,
    selectedProductMaxStock,
    selectedProductCurrentStock,
    selectedProductStocked,
    selectedProductStockedDate,
    selectedProductStockerReplaced,

    selectedReplaceFlag,
    selectedReplaceMessage,
    selectedReplaceDate,
    selectedReplaceUser,
    selectedProductIndependentPricing,
    toggleIndependentPricing,
    row,
    column,
    rowPosition,
    columnPosition,
    isLoading,
    isDriver,
    isStocker,
    deleteMachineProduct,
    selectedId,
    selectedMachineName,
    editMachineProduct,
    editCurrentStock,
    editMachineProductLocation,
    driverFillSelectedMachine,
    fillSelectedMachine,
    driverOtherFillAmount,
    clearSelectedProduct,
    otherFillAmount,
    setMachineStatusAll,
    handleChange,
    stockerShowOtherAmount,
    toggleStockerOtherAmount,
    driverShowOtherAmount,
    toggleDriverOtherAmount,
    stockerOtherAmount,
    driverOtherAmount,
    stockerShowReplace,
    toggleStockerReplace,
    toggleDriverReplace,
    driverShowReplace,
  } = useAppContext();

  if (isLoading) {
    return <Loading center />;
  }

  let date = moment(selectedProductStockedDate);
  date = date.format('MMM Do, YYYY, h:mm:ss a ');

  const handleDelete = (e) => {
    e.preventDefault();

    deleteMachineProduct(selectedId, selectedProductId);
    //Window.location.reload()
  };

  const handleEdit = (e) => {
    e.preventDefault();

    let updatedLocation = prompt(
      'Updated Location: (Leave blank to keep Location)',
    );
    if (!updatedLocation) {
      updatedLocation = selectedLocation;
    }

    let updatedPrice = prompt('Updated Price: (Leave blank to keep Price)');
    if (!updatedPrice) {
      updatedPrice = selectedProductPrice;
    }

    let updatedMaxStock = prompt(
      'Updatd Max Stock: (Leave blank to keep Max Stock',
    );
    if (!updatedMaxStock) {
      updatedMaxStock = selectedProductMaxStock;
    }

    editMachineProduct(
      selectedId,
      selectedProductId,
      updatedLocation,
      updatedPrice,
      updatedMaxStock,
    );
  };

  const handleStockEdit = (e) => {
    e.preventDefault();

    let updatedCurrentStock = prompt(
      'Update Current Stock: (Leave blank to keep Current Stock',
    );
    if (!updatedCurrentStock) {
      updatedCurrentStock = selectedProductCurrentStock;
    }

    editCurrentStock(selectedId, selectedProductId, updatedCurrentStock);
  };

  const handleMap = (e) => {
    e.preventDefault();

    let updatedRow = prompt('Insert Product Row position (begins at 0)');
    if (!updatedRow) {
      updatedRow = row;
    }

    let updatedColumn = prompt('Insert Product Column position (begins at 0');
    if (!updatedColumn) {
      updatedColumn = column;
    }

    editMachineProductLocation(
      selectedId,
      selectedProductId,
      updatedRow,
      updatedColumn,
    );
  };

  const onInputChanged = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });
  };

  const onStockerOtherAmountSubmit = (e) => {
    e.preventDefault();

    otherFillAmount(
      stockerOtherAmount,
      selectedLocation,
      selectedProductId,
      selectedId,
      selectedProductName,
      selectedMachineName,
    );
  };

  const onDriverOtherAmountSubmit = (e) => {
    e.preventDefault();

    driverOtherFillAmount(
      driverOtherAmount,
      selectedLocation,
      selectedProductId,
      selectedId,
      selectedProductName,
      selectedMachineName,
      selectedProductMaxStock,
    );
  };

  const neededStock = selectedProductMaxStock - selectedProductCurrentStock;

  return (
    <Wrapper>
      {selectedProductId ? (
        <>
          <header className={selectedProductStocked > 0 ? 'needs-fill' : ''}>
            <div className='main-icon'>{selectedLocation}</div>
            {selectedReplaceFlag && (
              <div className='replace-warning'>
                <MdOutlineWarning />
                {selectedReplaceDate}-{selectedReplaceMessage}:
                {selectedReplaceUser}
              </div>
            )}
            <div
              className={
                selectedProductStockerReplaced ? 'info-advisory' : 'info'
              }
            >
              <h5>
                {selectedProductStockerReplaced
                  ? `${selectedProductName} - STOCKER REPLACED`
                  : selectedProductName}
              </h5>
              <p>{selectedProductType}</p>
            </div>
            <div className='independent-pricing'>
              <label className='switch'>
                <input
                  type='checkbox'
                  onChange={() => {
                    toggleIndependentPricing(
                      selectedId,
                      selectedProductId,
                      selectedProductIndependentPricing,
                    );
                  }}
                  checked={selectedProductIndependentPricing}
                />
                <span className='slider round'></span>
              </label>
            </div>
          </header>
          <div className='content'>
            <div className='content-center'>
              <div>Sales: {selectedProductSales}</div>
              <div>Price: ${Number(selectedProductPrice).toFixed(2)}</div>
              <div>Max Stock: {selectedProductMaxStock}</div>
              <div>Current Stock: {selectedProductCurrentStock}</div>
              {isStocker && (
                <div style={{ color: neededStock > 0 ? 'red' : 'black' }}>
                  Stock Needed: {neededStock}
                </div>
              )}
              {/* Stocked Info */}
              {selectedProductStocked > 0 ? (
                <div>Stocked: {selectedProductStocked}</div>
              ) : (
                <div></div>
              )}
            </div>
            <div className='stocked-date'>
              <br></br>
              {date ? <div> Stocked Date: {date}</div> : <div></div>}
            </div>
            <footer>
              <div className='action'>
                <>
                  {isDriver ? (
                    <>
                      <button
                        type='button'
                        className='btn edit-btn'
                        onClick={() => {
                          driverFillSelectedMachine(
                            selectedProductMaxStock,
                            selectedLocation,
                            selectedProductId,
                            selectedId,
                            selectedProductName,
                            selectedMachineName,
                          );
                        }}
                      >
                        Deliver - Fill
                      </button>
                      <button
                        type='button'
                        className='btn edit-btn'
                        onClick={() => toggleDriverOtherAmount()}
                      >
                        Other Amount
                      </button>
                      {selectedProductStockerReplaced && (
                        <button
                          type='button'
                          className='btn edit-btn'
                          onClick={() => toggleDriverReplace()}
                        >
                          Replace
                        </button>
                      )}
                      <button
                        type='button'
                        className='btn edit-btn'
                        onClick={() => clearSelectedProduct()}
                      >
                        Hide
                      </button>
                    </>
                  ) : (
                    <>
                      {isStocker ? (
                        <>
                          <button
                            type='button'
                            className='btn edit-btn'
                            onClick={() => {
                              fillSelectedMachine(
                                neededStock,
                                selectedLocation,
                                selectedProductId,
                                selectedId,
                                selectedProductName,
                                selectedMachineName,
                              );
                            }}
                          >
                            Stock - Fill
                          </button>

                          <button
                            type='button'
                            className='btn edit-btn'
                            onClick={() => toggleStockerOtherAmount()}
                          >
                            Other Amount
                          </button>
                          <button
                            type='button'
                            className='btn edit-btn'
                            onClick={() => toggleStockerReplace()}
                          >
                            Replace
                          </button>
                          <button
                            type='button'
                            className='btn edit-btn'
                            onClick={() => clearSelectedProduct()}
                          >
                            Hide
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type='button'
                            className='btn edit-btn'
                            onClick={handleEdit}
                          >
                            Edit
                          </button>
                          <button
                            type='button'
                            className='btn edit-btn'
                            onClick={handleStockEdit}
                          >
                            Edit Stock
                          </button>
                          {/* <button type='button' className='btn edit-btn' onClick={handleMap} >
                                        {(rowPosition >= 0 || columnPosition >= 0) ? (<div>Update Map</div>) : (<div>Map</div>)}
                                    </button> */}
                          <button
                            type='button'
                            className='btn edit-btn'
                            onClick={() => clearSelectedProduct()}
                          >
                            Hide
                          </button>
                          <button
                            type='button'
                            className='btn delete-btn'
                            onClick={handleDelete}
                          >
                            Delete
                          </button>
                        </>
                      )}
                    </>
                  )}

                  {stockerShowOtherAmount && (
                    <>
                      <br></br>
                      <br></br>
                      <form
                        method='post'
                        action='#'
                        id='#'
                        onSubmit={onStockerOtherAmountSubmit}
                      >
                        <div className='form-group files'>
                          <label>Select Other Amount </label>
                          <input
                            type='tel'
                            id='stockOtherAmount'
                            name='stockerOtherAmount'
                            value={stockerOtherAmount}
                            onChange={onInputChanged}
                            pattern='[0-9]*'
                          />
                        </div>

                        <button>Submit</button>
                      </form>
                    </>
                  )}

                  {driverShowOtherAmount && (
                    <>
                      <br></br>
                      <br></br>
                      <form
                        method='post'
                        action='#'
                        id='#'
                        onSubmit={onDriverOtherAmountSubmit}
                      >
                        <div className='form-group files'>
                          <label>Select Other Amount </label>
                          <input
                            type='tel'
                            id='driverOtherAmount'
                            name='driverOtherAmount'
                            value={driverOtherAmount}
                            onChange={onInputChanged}
                            pattern='[0-9]*'
                          />
                        </div>

                        <button>Submit</button>
                      </form>
                    </>
                  )}
                </>
              </div>
            </footer>
          </div>
        </>
      ) : (
        <div className='no-product'>
          <h5>No Product Selected</h5>
        </div>
      )}
      {stockerShowReplace && (
        <div>
          {<StockerReplaceProduct />}
          <br></br>
        </div>
      )}
      {driverShowReplace && <div>{<DriverReplaceProduct />}</div>}
    </Wrapper>
  );
};

export default SelectedProductInfoContainer;
