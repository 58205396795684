import React from 'react';
import logo from '../assets/images/logo.svg';
import favicon from '../assets/images/favicon.ico';

const Logo = () => {
    return <img src={favicon} alt='Vendy' className='logo' />
}

export default Logo;

