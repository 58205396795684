import React from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/Job';
import moment from 'moment';

const  User = ({ 
    _id, 
    name,
    email,
    lastName,
    status,
    role,
    createdAt
}) => {

    const { setEditUser, deleteUser, setUser } = useAppContext();  
    let date = moment(createdAt);
    date = date.format('MMM Do, YYYY ')
    return (
        <Wrapper>
            <header>
                <div className='main-icon'>
                    {name.charAt(0)}
                </div>
                <div className='info'>
                    <h5>{name} {lastName}</h5>
                    <p>{role} - {status ? "Active" : "Inactive"}</p>
                </div>
            </header>
            <div className='content'>
                <div className='content-center'>
                    <div>Email: {email}</div>
                </div>
                <footer>
                    <div className='action'>
                    <Link to='/user-main' className='btn view-btn' onClick={() => setUser(_id)}>
                                    View 
                                </Link>
                    {/* <button type='button' className={showActions ? 'btn hide-btn' : 'btn view-btn'} onClick={() => toggleUserActions(_id)} >
                        {showActions ? 'Hide Actions' : 'View Actions'}
                        </button> */}
                        <Link to='/add-user' className='btn edit-btn' onClick={() => setEditUser(_id)}>
                            Edit 
                        </Link>
                        {/* <button type='button' className={!activity ? 'btn activate-btn': 'btn inactivate-btn'} onClick={() => console.log("View Clicked")} >
                            {!activity ? 'Activate' : 'Inactivate'}
                        </button> */}
                        <button type='button' className='btn delete-btn' onClick={() => deleteUser(_id)} >
                            Delete
                        </button>
                    </div>
                </footer>
            </div>
            
        </Wrapper>
    )
}

export default User;