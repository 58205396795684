import React, { useEffect } from 'react'

import { useAppContext } from '../context/appContext';

const DailyTransactions = () => {

    const {
        getDailyTransactions,
        dailyReports,
        dailyProcessedDate,
        dailyTransactions,
        dailyTotal,
    } = useAppContext()

    useEffect(() => {
        getDailyTransactions()
        // eslint-disable-next-line
    }, [])

    const originalDate = new Date(dailyProcessedDate);

    const year = originalDate.getUTCFullYear();
    const month = (originalDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = originalDate.getUTCDate().toString().padStart(2, '0');
    const hours = originalDate.getUTCHours().toString().padStart(2, '0');
    const minutes = originalDate.getUTCMinutes().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

  return (
    <>
        <h2>Daily Transactions</h2>
        <hr></hr>
        <p>Reports Run: {dailyReports} Processed Date: {formattedDate} Transactions: {dailyTransactions} Total Sales: ${dailyTotal.toFixed(2)}</p>
    </>
    
  )
}

export default DailyTransactions