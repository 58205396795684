import styled from 'styled-components'

const Wrapper = styled.article`
  background: var(--white);
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  width: 90%;

  .replaced-product {
    margin-left: 10px;
    margin-right: 10px;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .replaced-product button {
    margin-bottom: 10px;
  }

`

export default Wrapper